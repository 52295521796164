<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">Log</p>
    </div>
    
    <v-card class="dialogmargin card pb-4" style="margin-bottom: 5rem">
      <v-layout row="wrap" align-center class="mx-5">
          <v-flex shrink my-5 class="switch-btn">
          <v-btn
            block
            tile
            outlined
            class="text"
            @click="onClickActive"
            :style="{
              'border-radius': '5px 0 0 5px',
              height: '40px',
              'text-transform': 'none',
              'background-color': running.backcolor,
              color: running.color,
              border: running.border,
            }"
            >{{ $t("active") }}</v-btn
          >
        </v-flex>
        <v-flex shrink my-5 class="switch-btn">
          <v-btn
            block
            class="text"
            depressed
            tile
            @click="onClickInactive"
            :style="{
              'border-radius': '0 5px 5px 0',
              height: '40px',
              'text-transform': 'none',
              'background-color': removed.backcolor,
              color: removed.color,
              border: removed.border,
            }"
            >{{ $t("inactive") }}</v-btn
          >
        </v-flex>
        
        <v-spacer></v-spacer>

        <v-col class="d-flex justify-end">
          <v-col>
          <v-text-field
            class="search"
            color="#F99D20"
            v-model="searchtxt"
            outlined
            dense
            style="
              margin-top: 1.7rem;
              width: 250px;
            "
            :placeholder="$t('Search')"
          ></v-text-field>
          <!-- :placeholder="$t('searchwithlogID')" -->
        </v-col>
        <v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize"
            width="120"
            max-height="35"
            style="margin-top: 1.8rem!important;"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            @click="onclickExportDialog"
            color="#2E7D32"
            outlined
            class="mt-1"
            width="180px"
            max-height="35"
            style="margin-top: 1.8rem!important;"
          >
            <img width="20" height="20" :src="images.excel" alt class="mr-3" />
            <!-- <export-excel :data="LogList" name="Log.xls">Export XLS</export-excel> -->
            {{ $t("exportExcel") }}
          </v-btn>
        </v-col>
        </v-col>
        
      </v-layout>

      <v-layout row="wrap" align-center class="mx-5">
        <p class="mt-7">{{ $t("from") }}</p>
        <v-col cols="12" sm="4" md="2">
          <date-picker
            v-bind:clearable="false"
            v-model="reportDateFrom"
            format="DD/MM/YYYY"
            :disabled="disablepicker"
            :disabled-date="
              (date) =>
                date < (Isdisable ? notBefore(reportDateTo) : null) ||
                date > new Date() ||
                date > new Date(reportDateTo)
            "
            placeholder="DD/MM/YYYY"
            class="fromdate custom-vuedatepicker2"
            style="
              margin-top: 0.5rem;
              width: 150px;
              border-radius: 5px;
            "
            @change="OnChangeSDate()"
          >
            <!-- <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template> -->
          </date-picker>
        </v-col>

        <p class="mt-7 tolabel">{{ $t("to") }}</p>
        <v-col cols="12" sm="4" md="2">
          <date-picker
            v-bind:clearable="false"
            class="todate custom-vuedatepicker2"
            v-model="reportDateTo"
            format="DD/MM/YYYY"
            width="100%"
            :disabled="disablepicker"
            :disabled-date="
              (date) =>
                date < BeforeFromDate() ||
                date > notAfter(reportDateFrom) ||
                date > new Date()
            "
            @change="OnChangeDate()"
            placeholder="DD/MM/YYYY"
            style="
              margin-top: 0.5rem;
              width: 150px;
              border-radius: 5px;
            "
          >
            <!-- <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template> -->
          </date-picker>
        </v-col>

        <!-- <v-row class="pl-md-0 mt-5" style="margin-left:5px;"> -->
        <div style="width: 250px" class="ml-8 mt-7">
          <v-autocomplete
            v-model="customDDL"
            :items="selectDDL"
            item-text="text"
            item-value="value"
            color="#F99D20"
            placeholder="Custom"
            class="text customddl"
            clearable
            outlined
            dense
            @change="onChangecustomDDL(customDDL)"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </div>
        <div class="ml-5 mt-7 logtypeddl">
          <v-autocomplete
            v-model="logtype"
            :items="logtypeDDL"
            item-text="text"
            item-value="text"
            color="#F99D20"
            placeholder="Log Level"
            class="text"
            clearable
            outlined
            dense
            @change="GetLogList()"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </div>
        <!-- <div style="width: 310px" class="ml-10 mt-7">
          <v-row>
          
          </v-row>
        </div> -->
        <!-- </v-row> -->
      </v-layout>

      <v-data-table
        style="margin-left: 13px"
        class="mytable"
        :headers="headers"
        :items="LogList"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        header-props.sort-icon="mdi-menu-down"
        hide-default-footer
        hide-default-header
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th
                style="cursor: pointer; min-width: 120px !important"
                class="b-text"
                @click="sortFun('log_Date')"
              >
                {{ headers[0].text }}
                <v-icon small v-if="!log_Date">mdi-arrow-down</v-icon>
                <v-icon small v-if="log_Date">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 150px !important"
                class="b-text"
                @click="sortFun('application')"
              >
                {{ headers[1].text }}
                <v-icon small v-if="!application">mdi-arrow-down</v-icon>
                <v-icon small v-if="application">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 100px !important"
                class="b-text"
                @click="sortFun('logID')"
              >
                {{ headers[2].text }}
                <v-icon small v-if="!logID">mdi-arrow-down</v-icon>
                <v-icon small v-if="logID">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 110px !important"
                class="b-text"
                @click="sortFun('user_ID')"
              >
                {{ headers[3].text }}
                <v-icon small v-if="!user_ID">mdi-arrow-down</v-icon>
                <v-icon small v-if="user_ID">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 130px !important"
                class="b-text"
                @click="sortFun('username')"
              >
                {{ headers[4].text }}
                <v-icon small v-if="!username">mdi-arrow-down</v-icon>
                <v-icon small v-if="username">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 155px !important"
                class="b-text"
                @click="sortFun('correlation_ID')"
              >
                {{ headers[5].text }}
                <v-icon small v-if="!correlation_ID">mdi-arrow-down</v-icon>
                <v-icon small v-if="correlation_ID">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 145px !important"
                class="b-text"
                @click="sortFun('company_ID')"
              >
                {{ headers[6].text }}
                <v-icon small v-if="!company_ID">mdi-arrow-down</v-icon>
                <v-icon small v-if="company_ID">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 130px !important"
                class="b-text"
                @click="sortFun('iP_Address')"
              >
                {{ headers[7].text }}
                <v-icon small v-if="!iP_Address">mdi-arrow-down</v-icon>
                <v-icon small v-if="iP_Address">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 150px !important"
                class="b-text"
                @click="sortFun('description')"
              >
                {{ headers[8].text }}
                <v-icon small v-if="!description">mdi-arrow-down</v-icon>
                <v-icon small v-if="description">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 120px !important"
                class="b-text"
                @click="sortFun('logType')"
              >
                {{ headers[9].text }}
                <v-icon small v-if="!logType">mdi-arrow-down</v-icon>
                <v-icon small v-if="logType">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 130px !important"
                class="b-text"
                @click="sortFun('exception')"
              >
                {{ headers[10].text }}
                <v-icon small v-if="!exception">mdi-arrow-down</v-icon>
                <v-icon small v-if="exception">mdi-arrow-up</v-icon>
              </th>
              <th
                style="cursor: pointer; min-width: 120px !important"
                class="b-text"
                @click="sortFun('logTrace')"
              >
                {{ headers[11].text }}
                <v-icon small v-if="!logTrace">mdi-arrow-down</v-icon>
                <v-icon small v-if="logTrace">mdi-arrow-up</v-icon>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr @click="handleClick(item)">
            <td>
              {{ DateFormat(item.log_Date) }} {{ TimeFormat(item.log_Date) }}
            </td>
            <td>{{ item.application }}</td>
            <td>{{ item.logID }}</td>
            <td>{{ item.user_ID }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.correlation_ID }}</td>
            <td>{{ item.company_ID }}</td>
            <td>{{ item.iP_Address }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.logType }}</td>
            <td>{{ item.exception }}</td>
            <td>{{ item.logTrace }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>

      <div class="text-right" v-if="LogList.length > 10">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="6">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#F99D20"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog content-class="export-dialog" v-model="exportdialog" width="490" class="popupdialog">
      <v-card>
        <v-layout row="wrap" align-center pt-5 mx-3>
          <v-flex px-3 shrink class="title-text">{{ $t("export") }}</v-flex>
        </v-layout>
        <v-layout row="wrap" align-center pt-4 mx-3>
          <v-flex md12 px-3 class="text"> {{ $t("fileType") }}</v-flex>
          <v-flex md12 px-3>
            <v-text-field
              v-model="filetype"
              color="#F99D20"
              class="text"
              outlined
              dense
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row="wrap" align-center py-5 mx-3>
          <v-flex md6>
            <v-flex md12 px-3 class="text">
              {{ $t("start_date") }}
            </v-flex>
            <v-flex md12 px-3>
              <date-picker
                v-bind:clearable="false"
                v-model="startdate"
                format="DD/MM/YYYY"
                :disabled="disablepicker"
                class="custom-vuedatepicker"
                @change="OnChangeExportDate()"
                :disabled-date="
                  (date) => date > new Date() || date > new Date(enddate)
                "
                placeholder="Select Date"

              >
                <!-- <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template> -->
              </date-picker>
            </v-flex>
          </v-flex>
          <v-flex md6>
            <v-flex md12 px-3 class="text">
              {{ $t("enddata") }}
            </v-flex>
            <v-flex md12 px-2>
              <date-picker
                v-bind:clearable="false"
                v-model="enddate"
                format="DD/MM/YYYY"
                width="100%"
                class="custom-vuedatepicker"
                :disabled="disablepicker"
                :disabled-date="
                  (date) =>
                    date < BeforeExportFromDate() ||
                    date > notAfterExport(startdate) ||
                    date > new Date()
                "
                placeholder="Select Date"
                style="
                  margin-right: 3rem;
                "
              >
                <!-- <template slot="icon-calendar">
                  <img
                    src="@/assets/images/calendaricon.png"
                    style="width: 20px; height: 20px"
                  />
                </template> -->
              </date-picker>
            </v-flex>
          </v-flex>
        </v-layout>
        <v-card-actions style="margin-right: 3%" class="pb-5 pt-6">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" max-height="35" @click="CloseDialog()" text>{{
            $t("cancel") }}</v-btn>
          <v-btn color="#F99D20" class="mr-2 white--text text-capitalize" width="120" max-height="35"
          @click="Export()">{{ $t("export") }}</v-btn>
        </v-card-actions>

        <!-- <v-layout row="wrap" align-center justify-end py-5 mx-3>
          <v-flex>
            <v-btn
              block
              class="ma-2"
              @click="CloseDialog()"
              color="#707070;"
              style="color: #999999; text-transform: none"
              >{{ $t("cancel") }}</v-btn
            >
          </v-flex>
          <v-flex md6 pl-3 pr-3>
            <v-btn
              block
              depressed
              @click="Export()"
              color="#F99D20"
              style="color: white; text-transform: none"
              >{{ $t("export") }}</v-btn
            >
          </v-flex>
        </v-layout> -->
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="detailDialog" class="pb-5" persistent width="600">
      <v-card class="dcard">
        <v-row class="ml-0 mt-0 mb-0 mr-0">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <p class="txt16 ml-8 mt-3">{{ $t("date") }}</p>
              <v-spacer></v-spacer>
              <v-btn @click="closedetaildig()" icon class="mt-0 mr-4">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-row>
            <p class="txt14 ml-5">
              {{ convertUTCDateToLocalDate(showdetail.datedetail) }}
            </p>

            <p class="txt16 ml-5 mt-5">{{ $t("application") }}</p>
            <p class="txt14 ml-5">{{ showdetail.appdetail }}</p>

            <p class="txt16 ml-5 mt-5">log ID</p>
            <p class="txt14 ml-5">{{ showdetail.logdet }}</p>

            <p class="txt16 ml-5 mt-5">User ID</p>
            <p class="txt14 ml-5">{{ showdetail.userdet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("userName") }}</p>
            <p class="txt14 ml-5">{{ showdetail.namedet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("correlationID") }}</p>
            <p class="txt14 ml-5">{{ showdetail.corrdet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("companyID") }}</p>
            <p class="txt14 ml-5">{{ showdetail.comdet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("iPAddress") }}</p>
            <p class="txt14 ml-5">{{ showdetail.ipdet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("description") }}</p>
            <p class="txt14 ml-5">{{ showdetail.descdet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("logType") }}</p>
            <p class="txt14 ml-5">{{ showdetail.lgtypedet }}</p>

            <p class="txt16 ml-5 mt-5">{{ $t("exception") }}</p>
            <p class="txt14 ml-5">{{ showdetail.excepdet }}</p>

            <p class="txt16 ml-5 mt-5">Log Trace</p>
            <p class="txt14 ml-5">{{ showdetail.logtradet }}</p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import Vue from "vue";
import excel from "vue-excel-export";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import XLSX from "xlsx";
Vue.use(excel);

export default {
  components: { DatePicker },
  data() {
    return {
      searchlength: 0,
      Isdisable: false,
      range: false,
      active: [],
      inactive: [],
      filetype: "Excel (.xlsx)",
      enddate: new Date(),
      startdate: new Date(),
      exportdialog: false,
      detailDialog: false,
      showdetail: {
        datedetail: null,
        appdetail: null,
        logdet: null,
        userdet: null,
        namedet: null,
        corrdet: null,
        comdet: null,
        ipdet: null,
        descdet: null,
        lgtypedet: null,
        excepdet: null,
        logtradet: null,
      },
      startDate: null,
      endDate: null,
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      logdataAll: [],
      ddlValue: 1,
      customDDL: "",
      reportDateFrom: new Date(),
      reportDateTo: new Date(),
      // selectDDL: [
      //   {
      //     value: 1,
      //     text: "Today",
      //   },
      //   {
      //     value: 2,
      //     text: "Yesterday",
      //   },
      //   {
      //     value: 3,
      //     text: "This week (Sun-Today)",
      //   },
      //   {
      //     value: 4,
      //     text: "Last 7 days",
      //   },
      //   {
      //     value: 5,
      //     text: "Last week (Sun-Sat)",
      //   },
      // {
      //   value: 6,
      //   text: "Last 14 days",
      // },
      // {
      //   value: 7,
      //   text: "This month",
      // },
      // {
      //   value: 8,
      //   text: "Last 30 days",
      // },
      // {
      //   value: 9,
      //   text: "Last Month",
      // },
      // {
      //   value: 10,
      //   text: "All time",
      // },
      // ],
      images: {
        excel: require("@/assets/images/exccelicon@2x.png"),
      },

      log_Date: null,
      application: null,
      logID: null,
      user_ID: null,
      username: null,
      correlation_ID: null,
      description: null,
      logType: null,
      company_ID: null,
      iP_Address: null,
      exception: null,
      logTrace: null,

      removed: {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      },
      current: "active",
      running: {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      },
      disablepicker: false,
      logtype: "Critical",
      loadingdialog: false,
      url: enurl.apiUrl,
      LogList: [],
      LogData: [],
      tmpLog: [],
      tmpLogg: [],
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30, 40],
      searchtxt: "",
      logtypeDDL: [
        {
          value: 1,
          text: "Critical",
        },
        {
          value: 2,
          text: "High",
        },
        {
          value: 3,
          text: "Medium",
        },
        {
          value: 4,
          text: "Low",
        },
        {
          value: 5,
          text: "Verbose",
        },
      ],
      totalCount: 0,
      LogListExport: [],
      ExportLog: null,
      tempsheetname: null,
      count: 0,
      exportcount: 0,
    };
  },
  watch: {
    searchtxt: function () {
      if (this.searchlength == 1) {
        this.GetLogList();
      }
    },
  },
  mounted() {
    let self = this;
    self.GetLogList();
    //self.notBefore(self.reportDateFrom);
  },
  computed: {
    selectDDL() {
      return [
        { value: 1, text: this.$t("Today") },
        { value: 2, text: this.$t("Yesterday") },
        { value: 3, text: this.$t("Thisweek(Sunday-Today)") },
        { value: 4, text: this.$t("Last7days") },
        { value: 5, text: this.$t("LastWeek(Sun-Sat)") },
      ];
    },
    computedDateFormatted6() {
      return this.formatDate(this.startdate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.enddate);
    },
    headers() {
      return [
        {
          text: this.$t("date"),
          value: "log_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("application"),
          value: "application",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("logID"),
          value: "logID",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("userID"),
          value: "user_ID",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("username"),
          value: "username",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("correlationID"),
          value: "correlation_ID",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("companyID"),
          value: "company_ID",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("iPAddress"),
          value: "iP_Address",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("description"),
          value: "description",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("logType"),
          value: "logType",
          sortable: true,
          align: "left",
        },

        {
          text: this.$t("exception"),
          value: "exception",
          sortable: true,
          align: "left",
        },
        {
          text: this.$t("logTrace"),
          value: "logTrace",
          sortable: true,
          align: "left",
        },
      ];
    },
  },
  methods: {
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        self.searchlength = 1;
        let List = self.LogList;
        self.LogList = [];
        let temp = List.filter(
          (v) =>
            v.logID.toString().indexOf(self.searchtxt) > -1 ||
            v.user_ID.toString().indexOf(self.searchtxt) > -1 ||
            v.username.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 ||
            v.correlation_ID.toString().indexOf(self.searchtxt) > -1 ||
            v.company_ID.toString().indexOf(self.searchtxt) > -1 ||
            v.iP_Address.indexOf(self.searchtxt) > -1 ||
            v.description.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 ||
            v.exception.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 ||
            v.logTrace.toLowerCase().indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.LogList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetLogList();
      }
    },
    BeforeFromDate() {
      let today = new Date();
      let val = new Date(this.reportDateFrom);
      let fromdate = this.reportDateFrom.getDate();
      if (fromdate == today.getDate() && this.count == 0) {
        let d = new Date(val.setDate(val.getDate() - 1));
        return d;
      } else {
        let d = new Date(val.setDate(val.getDate()));
        return d;
      }
      // this.count = 1;
    },
    //ExportToDate
    BeforeExportFromDate() {
      let today = new Date();
      let val = new Date(this.startdate);
      let fromdate = this.startdate.getDate();
      if (fromdate == today.getDate() && this.exportcount == 0) {
        let d = new Date(val.setDate(val.getDate() - 1));
        return d;
      } else {
        let d = new Date(val.setDate(val.getDate()));
        return d;
      }
      // this.count = 1;
    },
    notBefore(v) {
      let val = new Date(v);
      let today = new Date().getDate();
      let todate = this.reportDateTo.getDate();
      if (todate == today) {
        let d = new Date(val.setDate(val.getDate() - 7));
        return d;
      } else {
        let d = new Date(val.setDate(val.getDate() - 6));
        return d;
      }
    },
    notAfter(v) {
      if (v) {
        const val = new Date(v);
        let d = new Date(val.setDate(val.getDate() + 6));
        if (d < new Date()) {
          return d;
        }
      }
    },
    //ExportTodate
    notAfterExport(v) {
      if (v) {
        const val = new Date(v);
        let d = new Date(val.setDate(val.getDate() + 6));
        if (d < new Date()) {
          return d;
        }
      }
    },
    OnChangeSDate() {
      this.count = 1;
      this.Isdisable = true;
      this.reportDateTo = this.reportDateFrom;
      this.GetLogList();
    },
    //ExportDate
    OnChangeExportDate() {
      this.exportcount = 1;
      this.enddate = "";
    },

    OnChangeDate() {
      let self = this;
      self.Isdisable = true;
      self.GetLogList();
    },
    sortFun(rowName) {
      let self = this;
      let keyName = [
        "log_Date",
        "application",
        "logID",
        "user_ID",
        "username",
        "correlation_ID",
        "description",
        "company_ID",
        "iP_Address",
        "exception",
        "logTrace",
        "logType",
      ];
      if (
        rowName == "log_Date" ||
        rowName == "application" ||
        rowName == "username" ||
        rowName == "iP_Address" ||
        rowName == "exception" ||
        rowName == "logType"
      ) {
        self.LogList = self.LogList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      }
      if (rowName == "description" || rowName == "logTrace") {
        self.LogList = self.LogList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      }
      if (
        rowName == "logID" ||
        rowName == "user_ID" ||
        rowName == "correlation_ID" ||
        rowName == "company_ID"
      ) {
        self.LogList = self.LogList.sort((a, b) =>
          self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }

      self.page = 1;
    },
    CloseDialog() {
      let self = this;
      self.exportdialog = false;
      self.startdate = new Date();
      self.enddate = new Date();
    },
    Export() {
      let self = this;
      let wb = XLSX.utils.book_new();
      try {
        if (this.startdate != "" && this.enddate != "") {
          let tempsdate = this.startdate.setHours(0);
          this.startdate.setMinutes(0);
          this.startdate.setSeconds(0);
          let startyearexport = moment(tempsdate)
            .utc()
            .add(1, "days")
            .format("DD/MM/YYYY");
          let startyear = moment(tempsdate).utc().format("DD/MM/YYYY");

          let tempedate = this.enddate.setHours(0);
          this.enddate.setMinutes(0);
          this.enddate.setSeconds(0);
          let endyear = moment(tempedate).utc().format("DD/MM/YYYY");
          let endyearexport = moment(tempedate)
            .utc()
            .add(1, "days")
            .format("DD/MM/YYYY");
          let loglvl = this.logtype;
          let url = `${this.url}Log/GetLogALL?startDate=${startyear}&endDate=${endyear}&logType=${loglvl}`;

          this.loadingdialog = true;
          axios.get(url).then(function (response) {
            self.LogListExport = response.data.data;
            let exportdata = self.LogListExport.filter(
              (d) =>
                self.LogDateFormat(d.log_Date) >= self.startdate.getDate() &&
                self.LogDateFormat(d.log_Date) <= self.enddate.getDate()
            );
            let LogExport = [];
            let active = exportdata.filter((d) => d.status == true);
            let inactive = exportdata.filter((d) => d.status != true);

            if (self.current == "active") {
              LogExport = active;
              // xlsx json data
              let wsdata = [];
              for (let v of LogExport) {
                wsdata.push({
                  ["Date"]: self.convertUTCDateToLocalDate(v.log_Date),
                  ["Application"]: v.application,
                  ["Log ID"]: v.logID,
                  ["User ID"]: v.user_ID,
                  ["User Name"]: v.username,
                  ["Correlation ID"]: v.correlation_ID,
                  ["Company ID"]: v.company_ID,
                  ["IP Address"]: v.iP_Address,
                  ["Description"]: v.description,
                  ["Log Type"]: v.logType,
                  ["Exception"]: v.exception,
                  ["Log Trace"]: v.logTrace,
                });
              }
              const ws = XLSX.utils.json_to_sheet(wsdata);
              // xlsx append sheet
              XLSX.utils.book_append_sheet(wb, ws, "Log");

              // xlsx sheet name

              if (self.current == "active") {
                self.tempsheetname = `OptimisticLogActiveExport_${startyearexport} to ${endyearexport}`;
              } else {
                self.tempsheetname = `OptimisticLogInactiveExport_${startyearexport} to ${endyearexport}`;
              }

              // xlsx write file
              XLSX.writeFile(wb, `${self.tempsheetname}.xlsx`, {
                compression: true,
              });
            } else {
              LogExport = inactive;
              // xlsx json data
              let wsdata = [];
              for (let v of LogExport) {
                wsdata.push({
                  ["Date"]: self.convertUTCDateToLocalDate(v.log_Date),
                  ["Application"]: v.application,
                  ["Log ID"]: v.logID,
                  ["User ID"]: v.user_ID,
                  ["User Name"]: v.username,
                  ["Correlation ID"]: v.correlation_ID,
                  ["Company ID"]: v.company_ID,
                  ["IP Address"]: v.iP_Address,
                  ["Description"]: v.description,
                  ["Log Type"]: v.logType,
                  ["Exception"]: v.exception,
                  ["Log Trace"]: v.logTrace,
                });
              }
              const ws = XLSX.utils.json_to_sheet(wsdata);
              // xlsx append sheet
              XLSX.utils.book_append_sheet(wb, ws, "Log");

              // xlsx sheet name

              if (self.current == "active") {
                self.tempsheetname = `OptimisticLogActiveExport_${startyearexport} to ${endyearexport}`;
              } else {
                self.tempsheetname = `OptimisticLogInactiveExport_${startyearexport} to ${endyearexport}`;
              }

              // xlsx write file
              XLSX.writeFile(wb, `${self.tempsheetname}.xlsx`, {
                compression: true,
              });
            }
            self.startdate = new Date();
            self.enddate = new Date();
            self.loadingdialog = false;
            self.exportdialog = false;
          });
        } else {
          alert("Please fill Start Date and End Date!!!");
        }
      } catch (error) {
        self.startdate = new Date();
        self.enddate = new Date();

        self.loadingdialog = false;
        self.exportdialog = false;
      }
    },
    handleClick(item) {
      let self = this;
      self.detailDialog = true;
      self.showdetail.datedetail = item.log_Date;
      self.showdetail.appdetail = item.application;
      self.showdetail.logdet = item.logID;
      self.showdetail.userdet = item.user_ID;
      self.showdetail.namedet = item.username;
      self.showdetail.corrdet = item.correlation_ID;
      self.showdetail.comdet = item.company_ID;
      self.showdetail.ipdet = item.iP_Address;
      self.showdetail.descdet = item.description;
      self.showdetail.lgtypedet = item.logType;
      self.showdetail.excepdet = item.exception;
      self.showdetail.logtradet = item.logTrace;
    },
    closedetaildig() {
      let self = this;
      self.detailDialog = false;
    },
    onclickExportDialog() {
      let self = this;
      self.exportdialog = true;
    },
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    onClickInactive() {
      let self = this;
      self.Isdisable = false;
      self.exportcount = 0;
      self.count = 0;
      self.reportDateFrom = new Date();
      self.reportDateTo = new Date();
      self.disablepicker = false;
      self.customDDL = "";
      self.logtype = "Critical";
      self.current = "remove";
      self.search = "";
      self.searchtxt = "";
      self.startdate = new Date();
      self.enddate = new Date();
      self.page = 1;
      self.removed = {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      };
      self.running = {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      };

      self.GetLogList();
      self.clearSort();
    },

    onClickActive() {
      let self = this;
      self.count = 0;
      self.exportcount = 0;
      self.Isdisable = false;
      self.reportDateFrom = new Date();
      self.reportDateTo = new Date();
      self.customDDL = "";
      self.disablepicker = false;
      self.logtype = "Critical";
      self.current = "active";
      self.startdate = new Date();
      self.enddate = new Date();
      self.search = "";
      self.page = 1;
      self.running = {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      };
      self.removed = {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      };
      self.GetLogList();
      self.clearSort();
    },

    onChangecustomDDL(value) {
      let self = this;
      self.disablepicker = true;
      var today = new Date();

      if (value != null) {
        switch (value) {
          case 1:
            self.reportDateFrom.setDate(today.getDate());
            self.reportDateTo.setDate(today.getDate());
            break;

          case 2:
            self.reportDateFrom.setDate(today.getDate() - 1);
            self.reportDateTo.setDate(today.getDate() - 1);
            break;

          case 3:
            var temp = today.getDay();
            self.reportDateFrom.setDate(today.getDate() - temp);
            break;

          case 4:
            self.reportDateFrom.setDate(today.getDate() - 7);
            self.reportDateTo.setDate(today.getDate() - 1);
            break;

          case 5:
            var temp1 = today.getDay() + 7;
            var temp2 = today.getDay() + 1;
            self.reportDateFrom.setDate(today.getDate() - temp1);
            self.reportDateTo.setDate(today.getDate() - temp2);
            break;

          default:
            self.disablepicker = false;
        }
      } else {
        self.disablepicker = false;
        self.reportDateFrom = new Date();
        self.reportDateTo = new Date();
      }
      self.GetLogList();
    },
    clearSort() {
      let self = this;
      self.log_Date = null;
      self.application = null;
      self.logID = null;
      self.user_ID = null;
      self.username = null;
      self.correlation_ID = null;
      self.description = null;
      self.logType = null;
      self.company_ID = null;
      self.iP_Address = null;
      self.exception = null;
      self.logTrace = null;
    },

    GetLogList() {
      try {
        let self = this;
        let loglvl = self.logtype;
        let startyear = null;
        let endyear = null;
        // let teststartyear=null;
        // let testendyear=null;
        if (self.reportDateFrom != null || self.reportDateTo != null) {
          let tempsdate = self.reportDateFrom.setHours(0);
          self.reportDateFrom.setMinutes(0);
          self.reportDateFrom.setSeconds(0);
          startyear = moment(tempsdate).utc().format("DD/MM/YYYY");

          let tempedate = self.reportDateTo.setHours(0);
          self.reportDateTo.setMinutes(0);
          self.reportDateTo.setSeconds(0);
          endyear = moment(tempedate).utc().format("DD/MM/YYYY");
          // endyear = `${days}/${months}/${years}`;
        }
        let url = `${self.url}Log/GetLogALL?logType=${loglvl}`;
        if (startyear != null) 
        {
          url += `&startDate=${startyear}`;
        }
        if (endyear != null) 
        {
          url += `&endDate=${endyear}`;
        }

        self.loadingdialog = true;
        self.searchlength = 0;
        axios.get(url).then(function (response) {
          if (response.data.data == 0 || response.data.data == null) {
            //alert(response.data.message);
            self.LogList = [];
          }
          let templog = response.data.data;
          self.LogList = templog.filter(
            (d) =>
              self.LogDateFormat(d.log_Date) >= self.reportDateFrom.getDate() &&
              self.LogDateFormat(d.log_Date) <= self.reportDateTo.getDate()
          );
          self.tmpLog = templog.filter(
            (d) =>
              self.LogDateFormat(d.log_Date) >= self.reportDateFrom.getDate() &&
              self.LogDateFormat(d.log_Date) <= self.reportDateTo.getDate()
          );
          // console.log(self.tmpLog)
          self.changeTab();
          self.loadingdialog = false;
        });
      } catch (error) {
        alert("error");
        self.loadingdialog = false;
      }
    },
    changeTab() {
      let self = this;
      const data = self.tmpLog;
      self.active = data.filter((d) => d.status == true);
      self.inactive = data.filter((d) => d.status != true);

      if (self.current == "active") {
        self.LogList = self.active;
      } else {
        self.LogList = self.inactive;
      }
    },
    LogDateFormat(value) {
      let temp = new Date(value + "Z");
      let day = temp.getDate().toString().padStart(2, 0);
      let output = `${day}`;
      return output;
    },
    DateFormat(value) {
      let output = "-";
      let temp = new Date(value + "Z");
      // alert(value);
      // alert(temp);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    TimeFormat(value) {
      let output = "-";
      let temp = new Date(value + "Z");
      try {
        let hours = temp.getHours();
        let minutes = temp.getMinutes();
        if (hours.toString().length == 1) output = `0${hours}:${minutes}`;
        if (minutes.toString().length == 1) output = `${hours}:0${minutes}`;
        if (hours.toString().length == 1 && minutes.toString().length == 1)
          output = `0${hours}:0${minutes}`;
        if (hours.toString().length != 1 && minutes.toString().length != 1)
          output = `${hours}:${minutes}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output =
        newDate.getDate() +
        "/" +
        (newDate.getMonth() + 1) +
        "/" +
        newDate.getFullYear();

      if (newDate.getHours() < 10) {
        output = output + "   " + "0" + newDate.getHours();
      } else {
        output = output + "   " + newDate.getHours();
      }

      if (newDate.getMinutes() < 10) {
        output = output + ":" + "0" + newDate.getMinutes();
      } else {
        output = output + ":" + newDate.getMinutes();
      }
      //console.log(output);
      return output;
    },
  },
};
</script>
<style>
.export-dialog.v-dialog.v-dialog--active {
  height: 486px;
  padding-top: 85px;
  box-shadow: none;
  overflow: unset;
  background: transparent;
}
.tolabel {
  margin-left: 1.5rem;
}
.dcard {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}
/* .mx-calendar-icon {
  display: none !important;
}

.mx-datepicker svg {
  display: none;
} */

.mx-input {
  border: 0px solid #fff !important;
}
</style>
<style scoped>
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
.txt16 {
  font-weight: 500;
  font-size: 16px;
}
.txt14 {
  font-weight: normal;
  font-size: 14px;
}
>>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
.logtypeddl {
  width: 150px;
}

>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}
>>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
>>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (max-width: 280px) {
  .export {
    position: absolute;
    margin-left: auto;
    margin-top: 5rem;
  }
}

@media only screen and (max-width: 414px) {
  .fromdate {
    margin-left: -0.8rem !important;
    margin-top: -0.5rem !important;
  }
  .todate {
    margin-left: -0.8rem !important;
    margin-top: -0.9rem !important;
  }
  .tolabel {
    margin-left: -0.2rem !important;
    margin-top: -1px !important;
  }
  .customddl {
    margin-left: -2rem !important;
    margin-top: -0.4rem !important;
  }
  .logtypeddl {
    margin-top: -0.09rem !important;
    margin-left: -0.09rem !important;
    width: 250px !important;
  }
  .search {
    margin-left: -0.5rem !important;
    width: 200px !important;
  }
}
@media only screen and (max-width: 768px) {
  .customddl {
    margin-left: -2rem !important;
    width: 200px !important;
  }
  .logtypeddl {
    margin-left: -0.05rem !important;
    width: 200px !important;
  }
  .search {
    margin-left: -0.5rem !important;
    width: 200px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .search {
    margin-left: -0.5rem !important;
    width: 200px !important;
  }
  .logtypeddl {
    width: 200px !important;
  }
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
</style>
