<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="margin-left: 4px;margin-top: 22px;display:flex;">
      <v-btn router icon color="white" :to="{ name: 'Announcement' }">
        <img src="@/assets/images/back button.png" class="mt-1" />
      </v-btn>
      <p class="txt-header">
        {{ $t("newAnnouncements") }}
      </p>
    </div>
    <!-- <div style="background-color: #f0f0f7; margin-bottom: 1rem;">
      <v-row>
        <v-btn
          icon
          router
          color="white"
          :to="{ name: 'Announcement' }"
          class="mt-6 ml-2">
          <img src="@/assets/images/back button.png" class="mt-1 ml-3" />
        </v-btn>
        <p class="ml-3 mt-5 mb-0  HeaderTag headertext">
         {{ $t("newAnnouncements") }}
        </p>
      </v-row>
    </div> -->
      
      <v-card class="dialogmargin pl-5 pr-5">
        <v-row class="mr-1 py-7">
          <v-spacer></v-spacer>
          <v-btn
            width="180px"
            color="#F99D20"
            @click="AddingAnnounce"
            class="white--text mt-2"
            router
            >{{ $t("publish") }}</v-btn
          >
        </v-row>
        <div class="myfont ml-3">
          <div>
            <p class="mb-2">{{ $t("Titles") }}<span class="red--text"> *</span></p>
            <v-text-field
              solo
              color="#F99D20"
              :error-messages="error.announcement_Title"
              v-model="announcement_Title"
            ></v-text-field>
          </div>
          <div>
            <p class="mb-2">{{ $t("details") }}<span class="red--text"> *</span></p>
            <v-textarea
              v-model="announcement_Detail"
              :error-messages="error.announcement_Detail"
              color="#F99D20"
              solo
              dense
            ></v-textarea>
          </div>
          <div>
            <v-row>
              <v-col cols="12" lg="6" xl="6" md="6" sm="6" xs="12">
                <p class="mb-2">{{ $t("startDate") }}<span class="red--text"> *</span></p>
                <!-- <v-menu
                  v-model="startmenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      solo
                      v-model="computedStartDate"
                      :error-messages="error.announcement_Start_Date"
                      append-icon="mdi-calendar-month-outline"
                      color="#F99D20"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="announcement_Start_Date"
                    no-title
                    @input="startmenu = false"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu> -->
                <date-picker 
                  v-bind:clearable="false" 
                  format="DD/MM/YYYY" 
                  :style="{ width: '100%' }" readonly 
                  v-model="announcement_Start_Date" 
                  :disabled-date="allowedDates" 
                  :editable="false" class="custom-vuedatepicker1">
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
              </v-col>
              <v-col cols="12" lg="6" xl="6" md="6" sm="6" xs="12">
                <p class="mb-2">{{ $t("expiryDate") }}<span class="red--text"> *</span></p>
                <!-- <v-menu
                  v-model="expirtyMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      solo
                      v-model="computedExpirtyDate"
                      append-icon="mdi-calendar-month-outline"
                      color="#F99D20"
                      :error-messages="error.announcement_Expiry_Date"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="announcement_Expiry_Date"
                    no-title
                    @input="expirtyMenu = false"
                    :allowed-dates="allowedDates"
                  ></v-date-picker>
                </v-menu> -->
                <date-picker 
                  v-bind:clearable="false" 
                  format="DD/MM/YYYY" 
                  class="custom-vuedatepicker1" 
                  :editable="false" :style="{ width: '100%' }" 
                  readonly v-model="announcement_Expiry_Date" 
                  :disabled-date="allowedEndDates"
                  >
                  <!-- <template slot="icon-calendar">
                      <v-icon>
                          mdi-calendar-month-outline
                      </v-icon>
                  </template> -->
                </date-picker>
              </v-col>
            </v-row>
            <div class="d-flex">
              <p class="mb-2 ">{{ $t("attachment") }}<span class="red--text"> *</span></p>
              <p class="ml-11 errorPtag" v-if="error.imageUrl">
               Please upload attachment
              </p>
            </div>
          </div>
          <div v-show="showButton">
            <v-btn
              color="#F89D1F"
              width="120px"
              style="float: center"
              @click="onPickFile"
              class="white--text"
            >
              {{ $t("Upload") }}
              <input
                ref="image"
                @change="onFilePicked"
                accept="image/*"
                type="file"
                style="display: none"
                id="profile_picture"
              />
            </v-btn>
          </div>

          <v-hover v-if="showButton==false" v-slot:default="{ hover }">
            <v-img
              v-if="showButton==false"
              :src="imageUrl"
              max-height="200px"
              @click="zoom(imageUrl)"
              width="200"
              aspect-ratio="1.4"
            >
              <v-expand-transition>
                <div v-if="hover" style="height: 100%; float: right">
                  <v-btn
                    @click="clearImge"
                    class="ma-2"
                    text
                    icon
                    small
                    color="red lighten-2"
                  >
                    <v-img src="@/assets/images/Mask Group 71.png" />
                  </v-btn>
                </div>
              </v-expand-transition>
            </v-img>
          </v-hover>
             <div class="text mt-4" style="color: red">
            <label>** {{ $t("Filetypeannouncement") }}</label>
            <br />
            <label>** {{ $t("Imagetype") }}</label>
            <br />
            <label>** {{ $t("Filesize") }}</label>
          </div>
          <!-- <div>
            <v-checkbox
             :label="$t('sendToAll')"
             :disabled="disablesendtoall"
              v-model="sendToAll"
              @change="checktoall(sendToAll)"
            ></v-checkbox>
            <v-checkbox
              class="mt-0"
              v-model="sentToSpecificEmployees"
               :disabled="disablespecific"
               @change="checktospecific(sentToSpecificEmployees)"
              :label="$t('sendToSpecificCompanies')"
            ></v-checkbox>
            <v-text-field
              :label="$t('inputCompanies')"
              v-if="sentToSpecificEmployees"
              :error-messages="error.specificCompany"
              solo
              v-model="specificCompany"
            ></v-text-field>

            <v-checkbox
              class="mt-0"
              v-model="disable_Comments"
               :label="$t('disableComments')"
            ></v-checkbox>
          </div> -->

            <div style="padding-bottom:1rem">
              <p style="margin-top: 5px">
                {{ $t("Audience") }}<span class="red--text"> *</span>
              </p>
              <v-radio-group row v-model="selectedOption" @change="changeradio">
                <v-row>
                  <v-col cols="12" md="2" lg="2" class="pl-4 pr-0 pt-0">
                    <v-radio
                      color="#F89D1F"
                      :label="$t('sendToAll')"
                      :value="0"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" md="2" lg="3" class="pl-0 pt-0">
                    <v-radio
                      color="#F89D1F"
                      :label="$t('sendToSpecificCompanies')"
                      :value="1"
                    >
                    </v-radio>
                    <v-text-field
                      autocomplete="off"
                      style="width: 600px"
                      :label="$t('inputCompanies')"
                      v-model="specificCompany"
                       v-if="sentToSpecificEmployees"
                      :error-messages="error.specificCompany"
                      class="ml-4 ml-md-0 mt-3"
                      single-line
                      solo
                      hide-details
                    ></v-text-field>
                    <span
                v-if="sendspecificcondi"
                style="font-size: 13px"
                class="red--text ml-2 mt-6"
                >{{ $t("Nospecificdata") }}</span>
                 <span
                v-if="nodatacondi"
                style="font-size: 13px"
                class="red--text ml-2 mt-6"
                >{{ $t("NoDatafound") }}</span>
                  </v-col>
                </v-row>
              </v-radio-group>
              <label>
                {{ $t("Comments") }}<span class="red--text"> *</span>
                <input type="checkbox" class="ml-3" v-model="disable_Comments" />
              </label>
            
            </div>
        </div>
        <v-dialog v-model="ImageDialog" persistent width="750">
          <v-card class="pb-4">
            <v-card-title>
              <span> </span>
              <v-spacer></v-spacer>
              <v-btn @click="ImageDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="8" md="8">
                  <div v-if="selectedImage">
                    <img
                      :src="selectedImage"
                      contain
                      aspect-ratio="2"
                      width="100%"
                      height="100%"
                      style="
                    background: #ffffff;
                    color: #707070;
                    font-size: 24px;
                    margin-left: auto;
                    margin-right: auto;
                  "
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
      
      <v-dialog v-model="loadingdialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear
              indeterminate
              color="#F99D20"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import moment from "moment";
import LogTrace from "@/api/Function.js";
export default {
  mixins: [LogTrace],
  props: ["isTrue"],
  data() {
    return {
      allowedDates: (val) => val >= new Date().toISOString().substr(0, 10),
      loadingdialog: false,
      selectedOption: 0,
      url: enurl.apiUrl,
      startmenu: false,
      expirtyMenu: false,
      isInputUser: false,
      showButton: true,
      sendToAll: true,
      sentToSpecificEmployees: false,
      imageUrl: "",
      imageName: "",
      imageFile: "",
      acceptImage: "",
      selectedImage: null,
      ImageDialog: false,
       filetypeerror: "",
      filesizeerror: "",
      error: {
        announcement_Title: "",
        announcement_Detail: "",
        announcement_Start_Date: "",
        announcement_Expiry_Date: "",
        specificCompany: "",
        imageUrl: "",
      },
      announcement_Title: "",
      announcement_Detail: "",
      announcement_Start_Date: "",
      announcement_Expiry_Date: "",
      announcement_Image: "",
      announcement_Send_All: "",
      specific_Person: "",
      disable_Comments: false,
      specificCompany: "",
      requestSpecificCompany: "",
      specificEmployeeCheck: true,
      isCompanyCheck: true,
      disablesendtoall:false,
      disablespecific:false,
      sendspecificcondi:false,
      nodatacondi:false,
    };
  },
  watch: {
    
    announcement_Title(v) {
      if (v) this.error.announcement_Title = "";
    },
    announcement_Detail(v) {
      if (v) this.error.announcement_Detail = "";
    },
    announcement_Start_Date(v) {
      if (v) this.error.announcement_Start_Date = "";
      if (new Date(v) > new Date(this.announcement_Expiry_Date)) {
        this.announcement_Expiry_Date = null;
      }
    },
    announcement_Expiry_Date(v) {
      if (v) this.error.announcement_Expiry_Date = "";
    },
    imageUrl(v) {
      if (v) this.error.imageUrl = "";
    },
    specificCompany(v) {
      if (v) this.error.specificCompany = "";
    },
     nodatacondi(v)
    {
      if(v==true)
      {
        this.sendspecificcondi=false;
      }
    },
    sendspecificcondi(v)
    {
      if(v==true)
      {
          this.nodatacondi=false;
      }
    },
  },

  computed: {
    computedStartDate() {
      return this.formatDate(this.announcement_Start_Date);
    },
    computedExpirtyDate() {
      return this.formatDate(this.announcement_Expiry_Date);
    },
  },
  mounted() {
    this.GetCompanyList();
  },
  methods: {
    allowedEndDates(date) {
      if (!this.announcement_Start_Date) return false;
      const startDate = new Date(this.announcement_Start_Date);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(date);
      endDate.setHours(0, 0, 0, 0); 
      return endDate < startDate;
    },
     checktospecific(v) {
      if (v==true) {
        this.sendToAll=false;
        this.disablesendtoall = true;
     //   this.sendToAll=false;
      } 
      else{
        this.disablespecific=false;
        this.disablesendtoall=false;
      }
    },
    checktoall(v) {
      if (v==true) {
        this.sentToSpecificEmployees=false;
        this.disablespecific = true;
     //   this.sentToSpecificEmployees=false;
      } else{
        this.disablespecific=false;
        this.disablesendtoall=false;
      }
    },
     changeradio() {
      
      let self = this;
      if (self.selectedOption === 1) {
        self.disablesendtoall = true;
        self.sendToAll=false;
       self.sentToSpecificEmployees = true;
       
       
        
      //  self.showtext = true;
      } else {
       
        self.disablesendtoall = false;
       // self.showtext = false;
        self.sendToAll = true;
        self.sentToSpecificEmployees = false;
      }
     },
    GetCompanyList() {
      let self = this;
      axios.get(`${self.url}Company/GetCompany`).then(function(response) {
        let conlist = response.data.data;
        self.companyList = conlist.filter((e) => e.company_ID != 0);
      });
    },
    zoom(url) {
      if (this.imageUrl) {
        this.ImageDialog = true;
        this.selectedImage = url;
      }
    },
    clearImge() {
      this.imageName = null;
      this.imageFile = null;
      this.imageUrl = null;
      this.showButton = true;
      document.getElementById("profile_picture").value = "";
    },
    // onFilePicked(e) {
    //   this.showButton = false;
    //   const files = e.target.files;
    //   if (files[0] !== undefined) {
    //     this.imageName = files[0].name;
    //     if (this.imageName.lastIndexOf(".") <= 0) {
    //       return;
    //     }
    //     const fr = new FileReader();
    //     fr.readAsDataURL(files[0]);
    //     fr.addEventListener("load", () => {
    //       this.imageUrl = fr.result;
    //       if (this.imageUrl) {
    //         this.showButton = false;
    //       }
    //       this.imageFile = files[0]; // this is an image file that can be sent to server...
    //     });
    //   } else {
    //     this.imageName = "";
    //     this.imageFile = "";
    //     this.imageUrl = "";
    //   }
    // },
    onFilePicked(e) {
      const files = e.target.files;
      let self = this;
      self.filesizeerror="";
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        let file = files[0];
        let filesize = Math.round(file.size / 1024).toString();
        let filetype = file.type.split("/")[1];
        if (filesize < 2048) {
          if (filetype == "png" || filetype == "jpeg" || filetype == "jpg") {
          //  self.showimg = true;
            self.filetypeerror = "";
           

            fr.onload = (e) => {
              var image = new Image();
              image.src = e.target.result;
              image.onload = function () {
                if (this.width > 256 || this.height > 256) {
                  self.filesizeerror = "File size error";
                  self.imageUrl = "";
                  alert("Uploaded photo must be 256x256 only");
                  if(self.filesizeerror!="")
                  {
                   // self.showimg=false;
                   self.imageUrl="";
                    self.showButton=true;
                     document.getElementById("profile_picture").value = "";
                  }
                  else{
                   // self.showimg=true;
                    self.showButton=false;
                  }
                }
                else{
                  if(self.filesizeerror=="")
                  {
                      self.showButton=false;
                  }
                }
              };
            };
           fr.addEventListener("load", () => {
              this.imageUrl = fr.result;
            
              this.imageFile = files[0];
          //      if (this.imageUrl) {
          //   this.showbutton = false;
          // }
            });
          } else {
            alert("Files must be png,jpg and jpeg only");
            self.showimg = false;
            self.filetypeerror = "filetypeerror";
          }
        } else {
          alert("Uploaded photo is greater than 2MB");
          this.showimg = false;
        }
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    onPickFile() {
      this.$refs.image.click();
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    
    AddingAnnounce() {
      this.errorMessage();
      let isCompanyCheck=true;
      let checknull=this.validationTrue();
       this.sendspecificcondi = false;
       this.nodatacondi=false;
      if(this.sendToAll!=true)
      {
        if(this.specificCompany==null || this.specificCompany=="")
            {
              this.sendspecificcondi = true;
            }
      }
      if (this.validationTrue() && this.specificEmployeeCheck) {
        if (
          moment(this.announcement_Start_Date) <
          moment(this.announcement_Expiry_Date).add(1, "day")
        ) {
          if (this.imageUrl.includes(",")) {
            this.realImage = this.imageUrl.split(",")[1];
          }
          this.loadingdialog = true;
          let combilearrary = [];
          let isCompanyExist = [];
          if (!this.sendToAll && this.specificCompany) {
            
            if (this.specificCompany.includes(",")) {
              let getCode = this.specificCompany.split(",");
              getCode = [...new Set(getCode)];
              getCode.forEach((element) => {
                let sameCompany = this.companyList.filter(
                  (x) => x.companyCode.toLowerCase().trim() == element.toLowerCase().trim()
                );
                if (sameCompany.length > 0) {
                  isCompanyExist.push(true);
                } else {
                  isCompanyExist.push(false);
                }
                this.tempImport = {
                  specificCompany: element.trim(),
                };
                combilearrary.push(this.tempImport);
              });
              isCompanyCheck = isCompanyExist.every((x) => x);
            } else {
              this.tempImport = {
                specificCompany: this.specificCompany.trim(),
              };
              combilearrary.push(this.tempImport);
              let sameCompany = this.companyList.filter(
                (x) => x.companyCode.toLowerCase().trim() == this.specificCompany.toLowerCase().trim()
              );
              if (sameCompany.length > 0) {
                isCompanyCheck = true;
              } else {
                isCompanyCheck = false;
                this.nodatacondi=true;
              }
            }
          }
          let specificEmployees = this.sentToSpecificEmployees;
          let showComment=true;
          if(this.disable_Comments==true)
          {
            showComment=false;
          }
          else{
            showComment=true;
          }
          let temp = {
            announcement_Title: this.announcement_Title,
            announcement_Detail: this.announcement_Detail,
            announcement_Start_Date: this.announcement_Start_Date,
            announcement_Expiry_Date: this.announcement_Expiry_Date,
            announcement_Image: this.realImage,
            announcement_Send_All: this.sendToAll,
            announcementCompanies: true,
            requestSpecificCompany: combilearrary,
            specific_Companies: specificEmployees ? true : false,
            employee_ID: this.$store.state.user_ID,
            disable_Comments: showComment,
          };
          let self = this;
          if(self.sendspecificcondi==false && self.nodatacondi==false)
          {
          if (isCompanyCheck) {
            axios
              .post(`${self.url}Announcement/AddnewAnnouncement`, temp)
              .then(function(response) {
                if (response.data.status == 0) {
                  alert(response.data.message);
                  //self.LogTrace(null, "Add Announcement", 46, "Low");
                  self.loadingdialog = false;
                  self.$router.push({ name: "Announcement" }).catch(()=>{});
                  self.combilearrary = [];
                }
              })
             .catch(function(error) {
              alert(error);
              self.loadingdialog = false;
              self.LogTrace(error, "Add Announcement Fail", 46, "Low");
            });
          } else {
            self.loadingdialog = false;
            self.nodatacondi=true;
            alert("Please check the company code again");
          }
          }
          self.loadingdialog=false;
          
        } else {
          alert("Start date can’t be after end date");
        }
      }
      if(checknull==false)
      {
        alert("Please fill in required fields");
      }
    },
    validationTrue() {
      if (
        this.announcement_Title &&
        this.announcement_Detail &&
        this.announcement_Start_Date &&
        this.announcement_Expiry_Date &&
        this.imageUrl
      ) {
        return true;
      }
      return false;
    },
    errorMessage() {
      const { error } = this;
      error.announcement_Title = !this.announcement_Title
        ? "Title is required"
        : "";
      error.announcement_Detail = !this.announcement_Detail
        ? "Details required"
        : "";
      error.announcement_Start_Date = !this.announcement_Start_Date
        ? "Start Date is required"
        : "";
      error.announcement_Expiry_Date = !this.announcement_Expiry_Date
        ? "Expiry Date is required"
        : "";
      error.imageUrl = !this.imageUrl
        ? "Please fill in the required fields"
        : "";
      if (this.sentToSpecificEmployees) {
        error.specificCompany = !this.specificCompany
          ? "Please fill in the required fields"
          : "";
        this.specificEmployeeCheck = this.specificCompany ? true : false;
      }
    },
  },
};
</script>

<style scoped>
.myfont {
  color: #8a8c8e;
}
/* .HeaderTag {
  cursor: pointer;
  font-size: 20px;
  font-family: "Kanit", sans-serif;
} */
.errorPtag {
  font-family: "Kanit", sans-serif;
  color: #ff5252 !important ;
  font-size: 12px;
}

>>> .v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
}
@media only screen {
  input {
    -webkit-appearance: none;
    appearance: none;
    width: 64px;
    padding-left: 8px;
    margin: 0;
    border-radius: 16px;
    background: radial-gradient(
        circle 12px,
        white 100%,
        transparent calc(100% + 1px)
      )
      #ccc 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }

  input::before {
    content: "OFF";
    font: 12px/32px Kanit;
    color: white;
  }

  input:checked {
    padding-left: 33px;
    padding-right: -16px;
    background-color: #f99d20;
    background-position: -16px;
  }

  input:checked::before {
    content: "ON";
  }
}
</style>
