<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7; margin-bottom: 1rem">
      <v-row>
        <v-btn
          icon
          router
          color="white"
          :to="{ name: 'Announcement' }"
          class="mt-5 ml-1"
        >
          <img src="@/assets/images/back button.png" class="mt-1 ml-3" />
        </v-btn>
        <p class="ml-3 mt-5  HeaderTag">
          {{ $t("AnnouncementDetails") }}
        </p>
      </v-row>
      <v-card class="mt-1 pl-5 pr-5">
        <v-card-text>
          <v-row class="ml-3 mr-3 mt-6">
            <v-list-item-avatar>
              <v-img src="@/assets/images/Group 3166.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="black--text">{{
                fullName
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                computedStartDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-row>
          <v-row class="ml-3 mr-3">
            <v-list-item-content>
              <v-list-item-title class="myfont">
                {{ $t("Titles") }}
              </v-list-item-title>

              <p class="black--text mt-3 myfont">
                {{ announcement_Title }}
              </p>
            </v-list-item-content>
          </v-row>
          <v-row class="ml-3 mr-3">
            <v-list-item-content>
              <v-list-item-title class="myfont">{{
                $t("details")
              }}</v-list-item-title>
              <p
                class="black--text mt-3 myfont"
                v-html="announcement_Detail"
              ></p>
            </v-list-item-content>
          </v-row>
          <v-row class="ml-3 mr-3">
            <v-list-item-content>
              <v-list-item-title class="myfont">{{ $t("expiryDate") }}</v-list-item-title>
              <v-list-item-subtitle class="black--text mt-3 myfont">{{
                computedExpirtyDate
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-row>
          <v-row class="ml-3 mr-3 mb-5">
            <v-list-item-content>
              <v-list-item-title class="myfont">{{
                $t("attachment")
              }}</v-list-item-title>
              <v-list-item-subtitle class="black--text mt-3 myfont">
                <!-- <v-img
                  max-height="200px"
                  width="200"
                  @click="zoom(imageUrl)"
                  :src="imageUrl"
                  aspect-ratio="1.4"
                ></v-img> -->
                   <div class="imageContainer">
                <v-img
                  
                  :src="imageUrl"
                  @click="zoom(imageUrl)"
                  class="image"
                >
                </v-img>
              </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-row>
        </v-card-text>
        <v-container v-show="!disable_Comments">
          <v-row class="ml-3 mr-3">
            <v-textarea
              solo
              height="80"
              v-model="comment"
              class="ml-1"
              :label="$t('LeaveAMessage')"
            ></v-textarea>
          </v-row>
          <v-row v-show="showPost">
            <v-col cols="12" class="pt-0 pr-0">
              <v-btn width="100px" text @click="showPost = false">Cancel</v-btn>
              <v-btn width="100px" text color="#0FA7D8" @click="addComment()">{{
                $t("Post")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="ml-8"
          v-for="(item, index) in commentList"
          :key="item.id"
          style="margin-top:-1rem;"
        >
          <v-row class=" mr-3">
            <v-list-item-avatar>
              <v-img :src="item.image ? item.image : images.adminImg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="black--text">{{
                item.fullName
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                formatDate(item.date)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-row>

          <v-row class="ml-11 mt-2" v-if="item.editCheck && item.deleteCheck">
            <div class="d-flex">
              <div class="mt-2">
                <p style="font-size: 18px">{{ item.comment }}</p>
              </div>
              <div
                class="d-flex"
                v-if="user_ID == item.employee_ID && item.isAdmin"
              >
                <div style="float: left; margin-left: 30px">
                  <v-btn icon @click="editComment(item.comment_ID, index)">
                    <img :src="images.editpic" />
                  </v-btn>
                </div>
                <div>
                  <v-btn icon @click="deleteComment(item.comment_ID, index)">
                    <img :src="images.deletepic" />
                  </v-btn>
                </div>
              </div>
            </div>
          </v-row>
          <div v-show="!item.editCheck">
            <v-row class="mt-3 mr-3">
              <v-textarea
                solo
                height="80"
                v-model="item.comment"
                class="ml-1"
                label="Leave A Message"
              ></v-textarea>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0 pr-0">
                <v-btn width="100px" text @click="cancelPost(index)"
                  >Cancel</v-btn
                >
                <v-btn
                  width="100px"
                  text
                  color="#0FA7D8"
                  @click="editedRow()"
                  >{{ $t("Post") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-show="!item.deleteCheck">
            <v-row class="ml-2 mt-2">
              <div class="ml-9 mt-2">
                <p style="font-size: 18px">{{ item.comment }}</p>
              </div>
              <v-col cols="12" class="pt-0 pr-0">
                <v-btn width="100px" text @click="cancelDelete(index)"
                  >Cancel</v-btn
                >
                <v-btn
                  width="100px"
                  text
                  color="#0FA7D8"
                  @click="deletedRow()"
                  >{{ $t("Delete") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-container>

        <v-dialog v-model="ImageDialog" persistent width="750">
          <v-card class="pb-4">
            <v-card-title>
              <span> </span>
              <v-spacer></v-spacer>
              <v-btn @click="ImageDialog = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="8" md="8">
                  <div v-if="selectedImage">
                    <img
                      :src="selectedImage"
                      contain
                      aspect-ratio="2"
                      width="100%"
                      height="100%"
                      style="
                    background: #ffffff;
                    color: #707070;
                    font-size: 24px;
                    margin-left: auto;
                    margin-right: auto;
                  "
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
export default {
  mixins: [LogTrace],
  props: ["id"],
  data() {
    return {
      url: enurl.apiUrl,
      fullName: "",
      imageUrl: "",
      imageName: "",
      imageFile: "",
      acceptImage: "",
      showPost: false,
      announcement_Title: "",
      editOrDelete: true,
      messages: "",
      showComments: true,
      announcement_Detail: "",
      announcement_Start_Date: "",
      announcement_Expiry_Date: "",
      announcement_Image: "",
      announcement_Send_All: "",
      specific_Person: "",
      user_ID: this.$store.state.user_ID,
      comment: "",
      disable_Comments: false,
      selectedImage: null,
      ImageDialog: false,

      editedComment: "",
      specificCompany: "",
      images: {
        adminImg: require("@/assets/images/Group 3166.png"),
        editpic: require("@/assets/images/Mask Group 178.png"),
        deletepic: require("@/assets/images/Mask Group 177.png"),
      },
      requestSpecificCompany: "",
      commentList: [],
      deleteRow: 0,
      editRow: 0,
      index: 0,
    };
  },

  computed: {
    computedStartDate() {
      return this.formatDate(this.announcement_Start_Date);
    },
    computedExpirtyDate() {
      return this.formatDate(this.announcement_Expiry_Date);
    },
    computedCommentDate(v) {
      return this.formatDate(v);
    },
  },
  mounted() {
    this.getAnnouncement();
    this.getComment();
  },
  watch: {
    comment(v) {
      this.showPost = v ? true : false;
    },
  },

  methods: {
    zoom(url) {
      this.ImageDialog = true;
      this.selectedImage = url;
    },
    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    async getAnnouncement() {
      // eslint-disable-next-line no-useless-catch
      try {
        let respone = await axios.post(
          `${this.url}Announcement/GetAnnouncementForAdminById`,
          { Announcement_ID: this.id }
        );
        let announcementList = respone.data.data;
        this.fullName = announcementList.full_Name;
        this.announcement_Title = announcementList.announcement_Title;
        this.announcement_Detail = announcementList.announcement_Detail.replace(
          /\n/g,
          "<br/>"
        );
        this.announcement_Start_Date = announcementList.announcement_Start_Date;
        this.announcement_Expiry_Date =
          announcementList.announcement_Expiry_Date;
        this.imageUrl = announcementList.announcement_Image;
        this.disable_Comments = announcementList.disable_Comments;
         this.LogTrace(null, "Get Announcement", 46, "Critical");
      } catch (error) {
         this.LogTrace(error, "Get Announcement Fail", 46, "Critical");
         alert(error);
      }
    },
    async addComment() {
      // eslint-disable-next-line no-useless-catch
      try {
        let temp = {
          comment: this.comment,
          announcement_ID: this.id,
          employee_ID: this.$store.state.user_ID,
        };
        let response = await axios.post(`${this.url}Comment/AddComment`, temp);
        if (response.data.status == 0) {
          alert(response.data.message);
           this.LogTrace(null, "Add Comment", 46, "Low");
          this.comment = "";
          this.showPost = true;
          this.getComment();
        }
      } catch (error) {
        this.LogTrace(error, "Add Comment Fail", 46, "Low");
        alert(error);
      }
    },
    async getComment() {
      // eslint-disable-next-line no-useless-catch
      try {
        let temp = {
          announcement_ID: this.id,
        };
        let response = await axios.post(
          `${this.url}Comment/GetCommentForAdmin`,
          temp
        );
        this.commentList = response.data.data.map((element) => ({
          ...element,
          editCheck: true,
          deleteCheck: true,
          
        }));
        this.LogTrace(null, "Get Comment", 46, "Critical");
      } catch (error) {
        alert(error);
        this.LogTrace(error, "Get Comment", 46, "Critical");
      }
    },
    editComment(v, index) {
      this.commentList[index].editCheck = false;
      this.commentList[index].deleteCheck = true;
      this.editRow = v;
      this.index = index;
    },
    cancelPost(index) {
      this.commentList[index].editCheck = true;
    },
    cancelDelete(index) {
      this.commentList[index].deleteCheck = true;
    },
    deleteComment(v, index) {
      this.commentList[index].deleteCheck = false;
      this.commentList[index].editCheck = true;
      this.deleteRow = v;
    },
    async editedRow() {
      // eslint-disable-next-line no-useless-catch
      try {
        let temp = {
          comment: this.commentList[this.index].comment,
          comment_ID: this.editRow,
        };
        let response = await axios.post(
          `${this.url}Comment/UpdateComment`,
          temp
        );
        if (response.data.status == 0) {
          alert(response.data.message);
          this.LogTrace(null, "Update Comment", 46, "Medium");
          this.getComment();
        }
      } catch (error) {
        alert(error);
        this.LogTrace(error, "Update Comment", 46, "Medium");
      }
    },

    async deletedRow() {
      // eslint-disable-next-line no-useless-catch
      try {
        let response = await axios.post(`${this.url}Comment/DeleteComment`, {
          comment_ID: this.deleteRow,
        });
        if (response.data.status == 0) {
          alert(response.data.message);
          this.LogTrace(null, "Delete Comment", 46, "High");
          this.getComment();
        }
      } catch (error) {
       alert(error);
      this.LogTrace(error, "Delete Comment", 46, "High");
      }
    },
  },
};
</script>

<style scoped>
image {
  max-width: 400px;
  max-height: 300px;
}
.imageContainer {
  width: 200px;
  height: auto;
  background-size: contain;
  margin-left: 20px;
}
.myfont {
  color: #8a8c8e;
}
.HeaderTag {
  cursor: pointer;
  font-size: 20px;
  font-family: "Kanit", sans-serif;
}
>>> .v-dialog::-webkit-scrollbar {
  width: 6px;
  background-color: none;
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background: #707070;
  outline: 1px solid #654321;
}
</style>
