import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


import Login from './views/Login.vue'
import CompanyManagement from './views/CompanyManagement.vue'
import NewCompany from './views/NewCompany.vue'
import UserManagement from './views/UserManagement.vue'
import Log from './views/Log.vue'
import Addemployee from './views/addemployee.vue'
import UsageReportType from './views/UsageReportByType.vue'
import Announcement from './views/Announcements/Announcement.vue'
import AddAnnouncements from './views/Announcements/AddAnnouncements.vue'
import EditAnnouncement from './views/Announcements/EditAnnouncement.vue'
import ReadMore from './views/Announcements/ReadMore.vue'
import Registration from './views/Registation.vue'
import Forgetpassword from './views/forgetpassword.vue'
import Resetpassword from './views/ResetPassword.vue'
import Version from './views/versionAndRelease.vue'
import Test from "./views/Test.vue"
import Notification from './views/Notification/Notification.vue'
import HelpAndFeedback from "./views/HelpAndFeedback.vue"
import DefaultLanguage from "./views/DefaultLanguage.vue"
import CustomerWebFeature from "./views/CustWebFeature.vue"
import BusinessSubscripe from "./views/BusinessSubscriptionReport.vue"
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/test',
            name: 'Test',
            component: Test
        },
        {
            path: '/Notification',
            name: 'Notification',
            component: Notification,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/usermanagement',
            name: 'UserManagement',
            component: UserManagement,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/forgetpassword',
            name: 'forgetpassword',
            component: Forgetpassword
        },
        {
            path: '/resetpassword/:Resetid',
            name: 'resetpassword',
            component: Resetpassword
        },
        {
            path: '/companymanagement',
            name: 'CompanyManagement',
            component: CompanyManagement,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/usermanagement/:addemployee',
            name: 'addemployee',
            component: Addemployee,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/usermanagement/:editemployee/:empid',
            name: 'editemployee',
            component: Addemployee,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/log',
            name: 'Log',
            component: Log,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/companymanagement/:edit/:id',
            name: 'EditCompany',
            component: NewCompany,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/companymanagement/:addcompany',
            name: 'addcompany',
            component: NewCompany,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Announcement',
            name: 'Announcement',
            component: Announcement,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Announcement/AddAnnouncement',
            name: 'addAnnouncement',
            component: AddAnnouncements,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Announcement/EditAnnouncement/:id',
            name: 'editAnnouncement',
            props: true,
            component: EditAnnouncement,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Announcement/ReadMore/:id',
            name: 'readMore',
            props: true,
            component: ReadMore,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Report/usagereportbytype',
            name: 'UsageReportType',
            component: UsageReportType,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Report/BusinessSubscripe',
            name: 'BusinessSubscripe',
            component: BusinessSubscripe,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Version',
            name: 'Version',
            component: Version,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/HelpAndFeedback',
            name: 'HelpAndFeedback',
            component: HelpAndFeedback,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/HelpAndFeedback/:notiString",
            name: "HelpAndFeedbackNotification",
            component: HelpAndFeedback,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/DefaultLanguage',
            name: 'DefaultLanguage',
            component: DefaultLanguage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/Registration',
            name: 'Registation',
            component: Registration
        },
        {
            path: '/Registration?lang=th',
            name: 'Registrationforthai',
            component: Registration
        },
        {
            path: '/CustomerWebFeature',
            name: 'CustomerWebFeature',
            component: CustomerWebFeature,
            meta: {
                requiresAuth: true
            }
        },
    ]
})
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (sessionStorage.getItem("token")) {
            return next();
        }
        next("/");
    } else {
        next();
    }
});
export default router;