<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <!-- <div style="background-color: #f0f0f7;">
        <p style="cursor: pointer;margin-left: 14px;margin-top: 22px;"
          class="headertext mb-0">
          {{ $t("announcements") }}
        </p>
    </div> -->
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px;margin-top: 22px;" class="headertext">
        {{ $t("announcements") }}
      </p>
    </div>
      
    <v-card class="pl-5 pr-5 pb-5 dialogmargin">
      <div>
        <v-row class="mr-1 py-5">
          <v-spacer></v-spacer>
          <v-btn
            width="180px"
            @click="newAnnouncement"
            color="#F99D20"
            class="white--text mt-2"
            router>
            {{ $t("newPost") }}
          </v-btn>
        </v-row>
        <div class="mycard">
          <v-row class="pl-6 pr-6" v-if="!announcementList.length">
            <v-col cols="12" xs="12" sm="6" md="6" lg="6">
              <p style="color: #e8931e; font-size: 16px">
                {{ $t("noAnnouncements") }}
              </p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col v-for="(item, i) in announcementList"
              :key="i"
              cols="12"
              xs="12"
              sm="5"
              md="5"
              lg="6">
              <div style="background-color:#E9E9E9; border-radius: 5px 5px 0 0;" class="pt-1">
                <p class="ml-4 mb-2">Created date {{item.createdAnnouncement_Date}}</p>
              <v-card style="border-radius: 0 0 5px 5px ;">
                <v-row no-gutters class="pa-0 ma-0">
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                    <div class="image-container">
                      <v-img
                        max-height="215"
                        max-width="1000px"
                        :src="item.announcement_Image">
                      </v-img>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                    <div class="
                        ml-4
                        mt-3
                        subtitle-1
                        d-inline-block
                        text-truncate" style="max-width: 150px">
                      {{ item.announcement_Title }}
                    </div>

                    <v-card-text class="text-truncate"
                      style="max-width: 400px">
                      {{ item.announcement_Detail }}
                    </v-card-text>
                    <p class="pa-4"
                      @click="readMore(item.announcement_ID)"
                      style="font-size: 12px; color: #0fa7d8;cursor: pointer">
                      {{ $t("ReadMore") }}
                    </p>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        class="ml-3"
                        text
                        icon
                        @click="readMore(item.announcement_ID)">
                        <v-img src="@/assets/images/msg.png" width="32px" v-if="item.disable_Comments == false">
                        </v-img>
                        <p  v-if="item.disable_Comments == false" class=" mb-0">{{ computedComment(item.announcement_ID) }}</p>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <div>
                        <v-btn icon
                          @click="eidtAnnouncement(item.announcement_ID)">
                          <img class="editIcon" :src="images.edit"/>
                        </v-btn>
                        <v-btn
                          icon
                          @click="openDeleteDialog(item.announcement_ID)">
                          <img
                            @click="deleteDialog = true"
                            :src="images.delete"
                            class="deleteIcon"/>
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- delete  dialog -->
      <v-dialog v-model="deleteDialog" persistent max-width="532">
        <v-card style="border-radius: 15px">
          <v-card-title>
            <p style="margin-top: 10px; margin-bottom: -30px">
              {{ $t("deleteitem") }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col align="center" cols="12">
                  <p style="font-size: 16px; color: #444444" class="pt-4">
                    {{ $t("deleteconfirm") }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions style="margin-right: 3%" class="pb-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 text-capitalize"
              width="120"
              max-height="35"
              @click="deleteDialog = false"
              text>
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="#FF2727"
              class="mr-2 white--text text-capitalize"
              width="120"
              max-height="35"
              @click="confirmDelete()">
              {{ $t("delete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    
    <v-dialog v-model="loadingdialog" persistent width="300">
      <v-card color="#FFF4EB" dark>
        <v-card-text class="black--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#F99D20" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
// import moment from "moment";
import LogTrace from "@/api/Function.js";
export default {
  mixins: [LogTrace],
  data() {
    return {
      loadingdialog: false,
      url: enurl.apiUrl,
      announcementList: [],

      // images: {
      //   edit: require("@/assets/images/Mask Group 178.png"),
      //   delete: require("@/assets/images/Mask Group 177.png"),
      // },
      items: [1, 2, 3, 4, 5, 6],
      isAnnouncements: "Announcements",
      isEdit: false,
      deleteDialog: false,
      Announcement_ID: 0,
      commentList: [],
    };
  },
  async mounted() {
    this.loadingdialog = true;
    await this.getcall();
    await this.getComment();
    this.loadingdialog = false;
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    computedComment() {
      return function (v) 
      {
        return this.commentList.filter((element) => element.announcement_ID == v).length;
      };
    },
    // announcement_Computed() {
    //   return this.announcementList.filter((element) => {
    //     const between = moment();
    //     return between.isBetween(
    //       moment(element.announcement_Start_Date),
    //       moment(element.announcement_Expiry_Date).add(1, "day")
    //     );
    //   });
    // },
  },
  methods: {
    newAnnouncement() {
      this.$router.push({ name: "addAnnouncement" }).catch(() => {});
    },

    eidtAnnouncement(v) {
      this.$router
        .push({ name: "editAnnouncement", params: { id: v } })
        .catch(() => {});
    },

    readMore(v) {
      this.$router
        .push({ name: "readMore", params: { id: v } })
        .catch(() => {});
    },

    async getComment() {
      // eslint-disable-next-line no-useless-catch
      try 
      {
        let response = await axios.get(
          `${this.url}Comment/GetCommentLengthAdmin`
        );
        this.commentList = response.data.data;
      } 
      catch (error) 
      {
        this.LogTrace(error, "Get Comment Fail", 46, "Critical");
        alert(error);
      }
    },

    async getcall() {
      // eslint-disable-next-line no-useless-catch
      try 
      {
        let respone = await axios.get(
          `${this.url}Announcement/GetAnnouncementAdmin`
        );
        let announcementList = respone.data.data;
        this.announcementList = announcementList.sort((a, b) => a.Announcement_ID > b.Announcement_ID ? 1 : -1);
      } 
      catch (error) 
      {
        this.LogTrace(error, "Get Announcement Fail", 46, "Critical");
        alert(error);
      }
    },

    openDeleteDialog(v) {
      this.Announcement_ID = v;
    },

    async confirmDelete() {
      // eslint-disable-next-line no-useless-catch
      try {
        this.loadingdialog = true;
        const response = await axios.post(
          `${this.url}Announcement/DeleteAnnouncement`,
          { Announcement_ID: this.Announcement_ID }
        );
        if (response.data.status == 0) 
        {
          alert(response.data.message);
          this.getcall();
          this.deleteDialog = false;
          this.loadingdialog = false;
        }
      } 
      catch (error) 
      {
        this.loadingdialog = false;
        this.LogTrace(error, "Delete Announcement Fail", 46, "High");
        alert(error);
      }
    },
  },
};
</script>

<style scoped>
.HeaderTag {
  margin-left: -1.3rem;
  cursor: pointer;
  font-size: 28px;
}
.mycard {
  overflow-y: auto;
  height: 720px;
  overflow-x: hidden;
}

>>> .v-dialog {
  border-radius: 20px !important;
}

.image-container {
  padding: 0px;
  margin: 0px;
  max-width: 500px;
  max-height: 210px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 330px) and (max-height: 570px) {
  .image-container {
    padding: 0px;
    margin: 0px;
    max-width: 210px;
    max-height: 210px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>
