<template >
  <div style="height: auto">
    <div v-if="!thankyou">
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="12" md="5" class="rghz pl-3">
          <div class="text-left pl-5" style="width: 520px;">
            <p style="font-size: 40px;">{{ $t("Tryforfree") }}</p>
            <p style="color: #959595; font-size: 30px; font-weight: lighter; font-family: 'Kanit' !important;">{{ $t("EasyforHR") }}</p>
            <p style="color: #F99D20; font-size: 40px;">{{ $t("90Free") }}</p>
          </div>
          <v-img
            :src="require('../assets/images/RegPic1.png')"
            class="ml-2 mt-10"
            contain
          />
        </v-col>
        <v-col cols="12" md="5" class="lefz">
          <div style="margin-top: 30px">
            <v-card style="border-radius:15px;">
              <v-card-title style="border-bottom: 1px solid #D2D2DA;">
                {{ $t("Signupfree") }}
              </v-card-title>
              <v-card-text>
                <v-row class="pb-0">
                  <v-col cols="12" md="12" class="pb-0">
                    <p style="color:#000000;font-size: 16px; margin-top: 5px;font-weight:500;">{{ $t("ContactInfo") }}</p>
                    <v-text-field
                      outlined
                      v-model="register.companyName"
                      :label="$t('companyName')+'*'"
                      hide-details
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.companyName"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companynamereq") }}</span
                    >
                    <span
                      v-if="errorMessage.companyNameexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companyexist") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-autocomplete
                      outlined
                      hide-details
                      v-model="register.businesstype"
                      style="margin-top: 0; font-size: 17px"
                      :items="itemss"
                      dense
                      :label="$t('Businesstype')+'*'"
                      item-value="val"
                      :item-text="(item) => ` ${item.text}`"
                      append-icon="mdi-chevron-down"
                      color="#F99D20"
                    >
                      
                    </v-autocomplete>
                    <span
                      v-if="errorMessage.businesstype"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("otherreq") }}</span
                    >
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <!-- <v-text-field
                      outlined
                    @keypress="onlyNumber"
                      :placeholder="$t('companysize')+'*'"
                      hide-details
                      v-model="register.companySize"
                      solo
                      dense
                      color="#F99D20"
                    ></v-text-field> -->
                    <v-autocomplete
                      outlined
                      hide-details
                      v-model="register.companySize"
                      style="margin-top: 0; font-size: 17px"
                      :items="CountrySizeList"
                      dense
                      :label="$t('companysize')+'*'"
                      item-value="val"
                      :item-text="(item) => ` ${item.txt}`"
                      append-icon="mdi-chevron-down"
                      color="#F99D20"
                    >
                    </v-autocomplete>
                    <span
                      v-if="errorMessage.companysize"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companysizereq") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row v-if="showothertype==true">
                  <v-col  cols="6">
                    <v-text-field
                      outlined
                      hide-details
                      :label="$t('Othertypes')"
                      v-model="register.othertype"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.othertype"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("otherreq") }}</span
                    >
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-text-field
                      outlined
                      @click="showmyinfo()"
                      @keyup="hidemyinfo()"
                      v-mask="maskCode"
                      hide-details
                      v-model="register.companyCode"
                      :placeholder="$t('companyCode')"
                      solo
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.companyCode"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companycodereq") }}</span
                    >
                    <span
                      v-if="errorMessage.companycodeexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("CompanyCodeexist") }}</span
                    >
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-text-field
                      outlined
                    @keypress="onlyNumber"
                      :placeholder="$t('companysize')"
                      hide-details
                      v-model="register.companySize"
                      solo
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.companysize"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companysizereq") }}</span
                    >
                  </v-col>
                </v-row> -->
                <v-row v-if="showinfo == true">
                  <v-col cols="6" md="6">
                    <div
                      v-if="!showeng"
                      style="border: solid thin #f99d20; border-radius: 10px"
                      class="pl-1 pr-1"
                    >
                      <v-row class="pa-0">
                        <v-col class="pa-0" cols="1">
                          <v-icon class="ml-2 iconi" color="#F99D20"
                            >mdi-information</v-icon
                          >
                        </v-col>
                        <v-col class="pl-2 pt-0 pr-1">
                          <label style="color: #f99d20" class="headtext"
                            >สามารถกำหนดตัวย่อบริษัทด้วยตนเอง
                            โดยมีจำนวนตัวอักษรไม่เกิน 5 ตัว เช่น PTT , Kbank , SCB ,
                            Fusio โดยต้องไม่ซ้ำ กับผู้เคยลงทะเบียนไว้แล้ว
                          </label>
                          <br />
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="showeng"
                      style="border: solid thin #f99d20; border-radius: 10px"
                      class="pl-1 pr-1"
                    >
                      <v-row class="pa-0">
                        <v-col class="pa-0" cols="1">
                          <v-icon class="ml-2 iconi" color="#F99D20"
                            >mdi-information</v-icon
                          >
                        </v-col>
                        <v-col class="pl-2 pt-0 pr-1">
                          <label style="color: #f99d20" class="headtext"
                            >The company code needs to be your company name's
                            abbreviation.With this code, you will be able to
                            distinguish your company's users from others. Must be 5
                            characters long and only accept English alphabets and
                            numbers. For example PTT, Kbank,SCG,Fusio.
                          </label>
                          <br />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-textarea
                      outlined
                      :label="$t('CompanyAddress')+'*'"
                      v-model="register.company_Address"
                      hide-details
                      dense
                      height="76"
                      color="#F99D20"
                    ></v-textarea>
                    <span
                      v-if="errorMessage.company_Address"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("otherreq") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0">
                    <v-text-field
                      outlined
                      hide-details
                      v-model="register.city"
                      :label="$t('Province/City')+'*'"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.city"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ errorMessage.city }}</span
                    >
                  </v-col>
                  <v-col class="pb-0">
                    <!-- <v-text-field
                      outlined
                      solo
                      hide-details
                      :placeholder="$t('country')+'*'"
                      v-model="register.country"
                      dense
                      color="#F99D20"
                    ></v-text-field> -->
                    <v-autocomplete
                      outlined
                      hide-details
                      v-model="register.country"
                      style="margin-top: 0; font-size: 17px"
                      :items="CountryList"
                      dense
                      :label="$t('country')+'*'"
                      item-value="txt"
                      :item-text="(item) => ` ${item.txt}`"
                      append-icon="mdi-chevron-down"
                      color="#F99D20"
                    >
                    </v-autocomplete>
                    <span
                      v-if="errorMessage.country"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ errorMessage.country }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-text-field
                      outlined
                      :label="$t('ContactName')+'*'"
                      hide-details
                      v-model="register.contact_Name"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.contact_Name"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("contactnamereq") }}</span
                    >
                    <!-- <span
                      v-if="errorMessage.usernameexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("Userexist") }}</span
                    > -->
                  </v-col>
                  <v-col cols="6" md="6" class="pb-0">
                    <v-text-field
                      outlined
                      :label="$t('telephone_number')+'*'"
                      v-mask="mask"
                      hide-details
                      v-model="register.tele_Number"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.tele_Number"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ errorMessage.tele_Number }}</span
                    >
                    <span
                      v-if="errorMessage.teleexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("Phoneexist") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-text-field
                      outlined
                      :label="$t('email')+'*'"
                      hide-details
                      v-model="register.company_Email"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.company_Email"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ errorMessage.company_Email }}</span
                    >
                    <span
                      v-if="errorMessage.companyemailexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("Emailexist") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <p style="color: #000000; font-size: 16px;font-weight:500;">{{ $t("SigninInfo") }}</p>
                    <v-text-field
                      outlined
                      @keyup="hidemyinfo()"
                      v-mask="maskCode"
                      hide-details
                      v-model="register.companyCode"
                      :label="$t('companyCode')+'*'"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.companyCode"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("companycodereq") }}</span
                    >
                    <span
                      v-if="errorMessage.companycodeexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("CompanyCodeexist") }}</span
                    >
                    <p style="color: #717070; font-size: 12px; margin-top: 5px; padding-left:2px; margin-bottom: 0px;">
                      {{ $t("companycodelongtxt") }}
                    </p>
                  </v-col>
                  <!-- <v-col cols="6">
                    <v-autocomplete
                      outlined
                      solo
                      hide-details
                      v-model="register.businesstype"
                      style="margin-top: 0; font-size: 17px"
                      :items="itemss"
                      @change="checkbusinesstype"
                      dense
                      :placeholder="$t('Businesstype')"
                      item-value="val"
                      :item-text="(item) => ` ${item.text}`"
                      append-icon="mdi-chevron-down"
                      color="#F99D20"
                    >
                      
                    </v-autocomplete>
                    <span
                      v-if="errorMessage.businesstype"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("otherreq") }}</span
                    >
                  </v-col>
                  <v-col  cols="6">
                    <v-text-field
                      outlined
                      v-if="showothertype==true"
                      solo
                      hide-details
                      :placeholder="$t('Othertypes')"
                      v-model="register.othertype"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.othertype"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("otherreq") }}</span
                    >
                  </v-col> -->
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-text-field
                      outlined
                      hide-details
                      v-model="register.username"
                      :label="$t('username')+'*'"
                      dense
                      color="#F99D20"
                    ></v-text-field>
                    <span
                      v-if="errorMessage.username"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("businessreq") }}</span
                    >
                    <span
                      v-if="errorMessage.usernameexist"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("Userexist") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-text-field
                      outlined
                      hide-details
                      v-model="register.password"
                      :label="$t('password')+'*'"
                      dense
                      color="#F99D20"
                      :type="show1 ? 'text' : 'password'"
                    
                    >
                      <template v-slot:append>
                        <v-btn icon color="white">
                          <img width="40" height="40" @click="show1 = !show1" :src="show1 ? pwdimage.showpwd : pwdimage.hidepwd"
                            alt />
                        </v-btn>
                      </template>
                    </v-text-field>
                    <span
                      v-if="errorMessage.password"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("businessreq") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <v-text-field
                      outlined
                      hide-details
                      v-model="register.cpassword"
                      :label="$t('ConfirmPwd')+'*'"
                      dense
                      color="#F99D20"
                      :type="show2 ? 'text' : 'password'"
                    >
                      <template v-slot:append>
                        <v-btn icon color="white">
                          <img width="40" height="40" @click="show2 = !show2" :src="show2 ? pwdimage.showpwd : pwdimage.hidepwd"
                            alt />
                        </v-btn>
                      </template>
                    </v-text-field>
                    <span
                      v-if="errorMessage.cpassword"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("businessreq") }}</span
                    >
                    <span
                      v-if="errorMessage.cpasswordNM"
                      style="font-size: 13px"
                      class="red--text ml-2"
                      >{{ $t("PwdNOtMatch") }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                <v-col cols="10" class="pa-0 ml-3">
                  <v-checkbox
                    required
                    @change="checkconfirm()"
                    v-model="checkapp"
                    color="#F89D1F"
                    class="text"
                  >
                    <template v-slot:label>
                      {{ $t("theploicything1") }} 
                      <a
                      style="
                          color: #F9B052;
                          margin-left: 5px;
                          margin-right: 5px;
                          text-decoration: underline;
                          font-size: 13px;
                          cursor: pointer;
                        "
                        href="https://www.optimistic-app.com/privacy-policy/"
                        target="_blank"
                        v-bind="props"
                        @click.stop
                      >
                      {{ $t("theploicything2") }} 
                      </a>
                      {{ $t("theploicything3") }}
                    </template>
                  </v-checkbox>
                </v-col>
                <label class="red--text mb-4 ml-5" v-show="showerror">
                  {{ $t("Confirmationrequire") }}</label
                >
              </v-row>
              <v-btn
                color="#F99D20"
                block
                style="
                  height: 52px;
                  font-size: 17px;
                  color: white;
                  border-radius: 10px;
                "
                depressed
                :loading="loading"
                class="text-capitalize kanit mb-5 mt-2"
                @click="AddCompany"
                >{{ $t("Applyservice") }}
              </v-btn>
              </v-card-text>
            </v-card>
            
          

            <!-- <v-row>
              <v-col cols="6" md="6" class="pb-0">
                {{ $t("packagename")}}
                <v-select
                  outlined
                  :items="packageList"
                  v-on:change="getthatnumber(packagename)"
                  rounded
                  hide-details
                  v-model="packagename"
                  solo
                  dense
                  color="#F99D20"
                ></v-select>
                <span
                  v-if="errorMessage.packagename"
                  style="font-size: 13px"
                  class="red--text ml-2"
                  >{{ errorMessage.packagename }}</span
                >
              
              </v-col>
              <v-col cols="6" md="6" class="pb-0">
                {{ $t("activemps") }}
                <v-select
                  outlined
                  :items="ActiveEmpList"
                  
                  rounded
                  hide-details
                  v-model="activeemp"
                  solo
                  dense
                  color="#F99D20"
                ></v-select>
              <span
                  v-if="errorMessage.activeemp"
                  style="font-size: 13px"
                  class="red--text ml-2"
                  >{{ errorMessage.activeemp }}</span
                >
              
              </v-col>
            </v-row> -->
            <!-- <v-row v-if="showinfo == true">
              <v-col cols="6" md="6">
                <div
                  v-if="!showeng"
                  style="border: solid thin #f99d20; border-radius: 10px"
                  class="pl-1 pr-1"
                >
                  <v-row class="pa-0">
                    <v-col class="pa-0" cols="1">
                      <v-icon class="ml-2 iconi" color="#F99D20"
                        >mdi-information</v-icon
                      >
                    </v-col>
                    <v-col class="pl-2 pt-0 pr-1">
                      <label style="color: #f99d20" class="headtext"
                        >สามารถกำหนดตัวย่อบริษัทด้วยตนเอง
                        โดยมีจำนวนตัวอักษรไม่เกิน 5 ตัว เช่น PTT , Kbank , SCB ,
                        Fusio โดยต้องไม่ซ้ำ กับผู้เคยลงทะเบียนไว้แล้ว
                      </label>
                      <br />
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-if="showeng"
                  style="border: solid thin #f99d20; border-radius: 10px"
                  class="pl-1 pr-1"
                >
                  <v-row class="pa-0">
                    <v-col class="pa-0" cols="1">
                      <v-icon class="ml-2 iconi" color="#F99D20"
                        >mdi-information</v-icon
                      >
                    </v-col>
                    <v-col class="pl-2 pt-0 pr-1">
                      <label style="color: #f99d20" class="headtext"
                        >The company code needs to be your company name's
                        abbreviation.With this code, you will be able to
                        distinguish your company's users from others. Must be 5
                        characters long and only accept English alphabets and
                        numbers. For example PTT, Kbank,SCG,Fusio.
                      </label>
                      <br />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row> -->
          </div>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="thankyou" class="text-center" style="margin: 0 auto;">
    <v-card style="max-width: 1150px; height: 600px; margin: 0 auto; margin-top: 4rem;" >
      <v-card-text class="text-center" >
        <v-img
          :src="require('../assets/images/cheerReg.png')"
          class="ml-2 mt-10"
          contain
          height="195"
        />
        <p style="color: #717070; font-size: 32px; margin-top: 2rem;">{{ $t("Thankyouforuclick") }}</p>
        <p style="color: #959595; font-size: 24px; margin-top: 2rem;line-height: 1.5;">{{ $t("successtxt") }}</p>
        <p style="color: #000000; font-size: 20px; margin-top: 4rem; font-weight: normal;">{{ $t("contatctustxt") }}</p>
        <v-btn
          color="#F99D20"
          style="
            min-width: 250px;
            height: 52px;
            font-size: 17px;
            color: white;
            border-radius: 5px;
            margin-top: 2rem;"
          depressed
          :loading="loading"
          class="text-capitalize kanit mb-5"
          @click="GotoHome"
          >{{ $t("StartTrial") }}
        </v-btn>
      </v-card-text>
    </v-card>

    </div>
  </div>
  <!-- <div v-show="!showeng" style="background-color: #ededed">
      <v-row no-gutters>
        <v-col cols="1"></v-col>
        <v-col cols="5" style="margin-top: 80px">
          <div class="text-center" style="color: #005371">
            <h1>ทดลองใช้ฟรี!!</h1>
            <h1>นาน 3 เดือน</h1>
          </div>
          <v-img
            :src="require('../assets/images/RegPic.png')"
            class="ml-2 mt-11"
            contain
            height="500"
          />
        </v-col>
        <v-col cols="5" class="ml-11" style="margin-top: 80px">
          <div class="text-center h1" style="color: #005371">
            <h1>เริ่มต้นง่าย ติดตั้งงาน</h1>
            <h1>ใช้งานง่าย</h1>
          </div>

          <div style="margin-top: 80px">
            <v-row class="pb-0">
              <v-col cols="6" md="6" class="pb-0">
                ชื่อบริษัท
                <v-text-field
                  outlined
                  rounded
                  solo
                  v-model="register.companyName"
                  @keyup="showandhideerror()"
                  :error-messages="errorMessage.companyName"
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6" class="pb-0">
                ชื่อย่อบริษัท หรือ หน่วยงาน
                <v-text-field
                  outlined
                  @click="showmyinfo()"
                  @keyup="hidemyinfo()"
                  v-mask="maskCode"
                  rounded
                  :error-messages="errorMessage.companyCode"
                  v-model="register.companyCode"
                  solo
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="showinfo == true">
              <v-col cols="6" md="6"></v-col>
              <v-col cols="6" md="6">
                <div
                  style="border: solid thin #f99d20; border-radius: 10px"
                  class="pa-3"
                >
                  <v-icon class="mr-2" color="#F99D20">mdi-information</v-icon>
                  <label style="color: #f99d20" class="headtext"
                    >สามารถกำหนดตัวย่อบริษัทด้วยตนเอง
                  </label>
                  <br />

                  <label
                    style="margin-left: 35px; color: #f99d20"
                    class="headtext"
                    >โดยมีจำนวนตัวอักษรไม่เกิน 5 ตัว เช่น PTT </label
                  ><br />
                  <label
                    style="margin-left: 35px; color: #f99d20"
                    class="headtext"
                    >Kbank , SCB , Fusio โดยต้องไม่ซ้ำ </label
                  ><br />
                  <label
                    style="margin-left: 35px; color: #f99d20"
                    class="headtext"
                    >บผู้เคยลงทะเบียนไว้แล้ว
                  </label>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="6" md="6">
                อีเมล์
                <v-text-field
                  outlined
                  rounded
                  solo
                  :error-messages="errorMessage.company_Email"
                  @keyup="showandhideerror()"
                  v-model="register.company_Email"
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                เบอร์โทรศัพท์
                <v-text-field
                  outlined
                  rounded
                  solo
                  v-mask="mask"
                  :error-messages="errorMessage.tele_Number"
                  @keyup="showandhideerror()"
                  v-model="register.tele_Number"
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                ประเทศ
                <v-text-field
                  outlined
                  rounded
                  solo
                  :error-messages="errorMessage.country"
                  @keyup="showandhideerror()"
                  v-model="register.country"
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
              <v-col>
                เมือง
                <v-text-field
                  outlined
                  rounded
                  solo
                  :error-messages="errorMessage.city"
                  @keyup="showandhideerror()"
                  v-model="register.city"
                  dense
                  color="#F99D20"
                ></v-text-field>
              </v-col>
            </v-row>
           
            <div>
              ที่อยู่
              <v-textarea
                outlined
                auto-grow
                rounded
                :error-messages="errorMessage.company_Address"
                @keyup="showandhideerror()"
                v-model="register.company_Address"
                solo
                dense
                color="#F99D20"
              ></v-textarea>
            </div>
            <v-row class="pa-0">
              <v-col cols="12" class="pa-0">
                <v-checkbox
                  label="ยืนยันการสมัครด้วยการ กด เช็คบ๊อค"
                  required
                  
                  v-model="checkapp"
                  class="text"
                ></v-checkbox>
              </v-col>
              <label class="red--text mb-4" v-show="showerror"
                >กรุณา ยืนยันการสมัครใช้บริการ</label
              >
            </v-row>

            <v-btn
              color="#F99D20"
              block
              style="
                height: 52px;
                font-size: 17px;
                color: white;
                border-radius: 10px;
              "
              depressed
              :loading="loading"
              class="text-capitalize kanit mb-5 mt-2"
              @click="AddCompany"
              >สมัครใช้บริการ
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div> -->
</template>

<script>
import moment from "moment";
import i18n from "@/translate/i18n";
import enurl from "@/api/environment";
import axios from "axios";
import { mask } from "vue-the-mask";
import LogTrace from "@/api/Function.js";

// function generatePassword() {
//   const charactersArray = "a-z,A-Z,0-9".split(",");

//   let CharacterSet = "";
//   let tempPassword = "";

//   if (charactersArray.indexOf("a-z") >= 0) {
//     CharacterSet += "abcdefghijklmnopqrstuvwxyz";
//   }
//   if (charactersArray.indexOf("A-Z") >= 0) {
//     CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   }
//   if (charactersArray.indexOf("0-9") >= 0) {
//     CharacterSet += "0123456789";
//   }
//   if (charactersArray.indexOf("#") >= 0) {
//     CharacterSet += "![]{}()%&*$#^<>~@|";
//   }
//   for (let i = 0; i < 10; i++) {
//     tempPassword += CharacterSet.charAt(
//       Math.floor(Math.random() * CharacterSet.length)
//     );
//   }
//   return tempPassword;
// }

export default {
  mixins: [LogTrace],
  props: ['CountryList', 'CountrySizeList'],
  directives: {
    mask,
  },
  watch: {
    "register.companyName"(v) {
      if (v) this.errorMessage.companyName = "";
      if (v) this.errorMessage.companyNameexist = "";
    },
    "register.companySize"(v) {
      if (v) this.errorMessage.companysize = "";
    },
    "register.businesstype"(v){
      if (v) this.errorMessage.businesstype = "";
      if(v!=30) this.errorMessage.othertype="";this.register.othertype="";
    },
     "register.othertype"(v){
      if (v) this.errorMessage.othertype = "";
    },
    "register.company_Email"(v) {
      if (v) this.errorMessage.company_Email = "";
      if (v) this.errorMessage.companyemailexist = "";
    },
    "register.tele_Number"(v) {
      if (v) this.errorMessage.tele_Number = "";
      if (v) this.errorMessage.teleexist = "";
    },
    "register.country"(v) {
      if (v) this.errorMessage.country = "";
    },
    "register.city"(v) {
      if (v) this.errorMessage.city = "";
    },
    "register.company_Address"(v) {
      if (v) this.errorMessage.company_Address = "";
    },
    "register.contact_Name"(v) {
      if (v) this.errorMessage.contact_Name = "";
    },
    "register.username"(v) {
      if (v) this.errorMessage.username = "";
      if (v) this.errorMessage.usernameexist = "";
    },
    "register.password"(v) {
      if (v) this.errorMessage.password = "";
      if (v) this.errorMessage.cpasswordNM = "";
    },
    "register.cpassword"(v) {
      if (v) this.errorMessage.cpassword = "";
      if (v) this.errorMessage.cpasswordNM = "";
    },
    // "register.companyCode"(v) {
    //   if (v) this.register.username = "admin" + v.toLowerCase();
    // },
    packagename(v) {
      if (v) this.errorMessage.packagename = "";
      if (v) this.register.package_ID = 1;
    },
    activeemp(v) {
      if (v) this.errorMessage.activeemp = "";
    },
  },
  data() {
    return {
      mask: "###########",
      showeng: true,
      maskCode: "XXXXX",
      packageList: [],
      ActiveEmpList: [],
      companyName: [],
      companyCode: [],
      time: 0,
      tele_Number: [],
      city: [],
      country: [],
      company_Email: [],
      company_Address: [],
      register: {
        companyName: "",
        companyCode: "",
        companySize: "",
        tele_Number: "",
        city: "",
        country: "",
        company_Email: "",
        company_Address: "",
        username: "",
        contact_Name: "",
        status: true,
        password: "",
        cpassword: "",
        activeFlag: true,
        expired_Date: moment().add(60, "days").toISOString(),
        register_Date: new Date(),
        isRegister: 1,
        package_ID: 1,
        businesstype:"",
        othertype:""
      },
      packagename: "",
      activeemp: null,
      showothertype:false,
      errorMessage: {
        companyName: "",
        companyNameexist: "",
        companysize: "",
        companyCode: "",
        companycodeexist: "",
        tele_Number: "",
        teleexist: "",
        city: "",
        country: "",
        company_Email: "",
        companyemailexist: "",
        usernameexist: "",
        company_Address: "",
        username: "",
        packagename: "",
        activeemp: "",
        password: "",
        cpassword: "",
        cpasswordNM: "",
        businesstype:"",
        othertype:"",
        contact_Name: ""
      },
      url: enurl.apiUrl,
      usernamelist: [],
      ComList: [],
      EmpList: [],
      loading: false,
      showinfo: false,
      checkapp: false,
      showerror: false,
      thankyou: false,
      show1: false,
      show2: false,
      pwdimage: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
    };
  },

  created() {
    this.$route.fullPath;
    if (this.$route.fullPath != "/Registration") {
      this.showeng = false;
      i18n.locale = "th";
    } else {
      this.showeng = true;
      i18n.locale = "en";
    }
    this.GetPackage();
    this.GetUserName();
    this.GetCompanyList();
    this.GetEmplist();
  },
  computed: {
    itemss() {
      return [
        { text: this.$t("Individual"), val: 1 },
        { text: this.$t("Hotel / Resort"), val: 2 },
        { text: this.$t("Restaurant"), val: 3 },
        { text: this.$t("Factory"), val: 4 },
        { text: this.$t("Office"), val: 5 },
        { text: this.$t("Merchandise"), val: 6 },
        { text: this.$t("Gas"), val: 7 },
        { text: this.$t("Securitycom"), val: 8 },
        { text: this.$t("Construction"), val: 9 },
        { text: this.$t("Transportcom"), val: 10 },
        { text: this.$t("Hospital"), val: 11 },
        { text: this.$t("Clinic"), val: 12 },
        { text: this.$t("School"), val: 13 },
        { text: this.$t("techIT"), val: 14 },
        { text: this.$t("Accounting"), val: 15 },
        { text: this.$t("Organizercom"), val: 16 },
        { text: this.$t("Marketcom"), val: 17 },
        { text: this.$t("Finance"), val: 18 },
        { text: this.$t("Insurance"), val: 19 },
        { text: this.$t("Outsourcing"), val: 20 },
        { text: this.$t("Personalcare"), val: 21 },
        { text: this.$t("Carshow"), val: 22 },
        { text: this.$t("Carcage"), val: 23 },
        { text: this.$t("Dormitory"), val: 24 },
        { text: this.$t("Association"), val: 25 },
        { text: this.$t("RentalCompany"), val: 26 },
        { text: this.$t("Service"), val: 27 },
        { text: this.$t("Tourism"), val: 28 },
        { text: this.$t("Transport"), val: 29 },
        { text: this.$t("Other"), val: 30 },
      ];
    },
  },
  methods: {
    // GetBusinesstypelist(){
    //   let self=this;
    //   axios.get(`${self.url}Company/GetCompany`).then(function (response) {});
    // },
    checkbusinesstype(){
      if(this.register.businesstype==30)
      {
          this.showothertype=true;
      }
      else{
        this.showothertype=false;
      }
    },
    showmyinfo() {
      this.showinfo = true;
    },
    checkconfirm() {
      if (this.checkapp == true) {
        this.showerror = false;
      }
    },
    GetPackage() {
      let self = this;
      axios
        .get(`${self.url}Package_Plan/GetPackagePlan`)
        .then(function (response) {
          self.tempoArray = response.data.data;
          let theLii = [];
          for (let i = 0; i < self.tempoArray.length; i++) {
            theLii.push(self.tempoArray[i].pkgName);
          }
          self.packageList = [...new Set(theLii)];
        })
        .catch(function (error) {
          alert(error);
        });
    },
    getthatnumber(name) {
      let self = this;
      self.ActiveEmpList = [];
      self.activeemp = null;

      let theLii = [];
      for (let i = 0; i < self.tempoArray.length; i++) {
        if (self.tempoArray[i].pkgName == name) {
          theLii.push(self.tempoArray[i].activeEmp);
        }
      }
      self.ActiveEmpList = [...new Set(theLii)];
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    GetCompanyList() {
      let self = this;
      axios.get(`${self.url}Company/GetCompany`).then(function (response) {
        let conlist = response.data.data;
        self.ComList = conlist.filter(
          (e) => e.company_ID != 0 && e.activeFlag != false
        );
      });
    },
    hidemyinfo() {
      // alert("gg")
      this.showinfo = false;
      if (this.time != 0) {
        if (this.register.companyCode != "") {
          this.errorMessage.companyCode = "";
          this.errorMessage.companycodeexist = "";
        }
      }
    },
    GetEmplist() {
      let self = this;
      axios
        .get(`${self.url}EmployeeInfo/GetallEmployeeInfo`)
        .then(function (response) {
          self.EmpList = response.data.data;
        });
    },
    GetUserName() {
      let self = this;
      axios.get(`${self.url}UserInfo/GetUserInfos`).then(function (response) {
        self.usernamelist = response.data.data;
      });
    },
    GetPackageID() {
      let self = this;
      let id = "";
      for (let i = 0; i < self.tempoArray.length; i++) {
        if (
          self.tempoArray[i].pkgName == self.packagename &&
          self.tempoArray[i].activeEmp == self.activeemp
        ) {
          id = self.tempoArray[i].pid;
        }
      }
      return id;
    },
    ValidateUserName() {
      let FilterUserNameList = this.usernamelist;
      for (let i = 0; i < FilterUserNameList.length; i++) {
        if (
          FilterUserNameList[i].userName.toLowerCase() ==
          this.register.username.toLowerCase()
        ) {
          // alert(this.$t("Userexist"));
          this.errorMessage.usernameexist = "Contact name  exists";
          return false;
        }
      }
      return true;
    },
    ValidateEmail() {
      let FilterUserNameList = this.usernamelist;
      let FilterComlist = this.ComList;
      let FilterEmplist = this.EmpList;
      if (
        this.register.company_Email != null &&
        this.register.company_Email != ""
      ) {
        for (let i = 0; i < FilterUserNameList.length; i++) {
          if (FilterUserNameList[i].email == this.register.company_Email) {
            // alert(this.$t("Emailexist"));
            this.errorMessage.companyemailexist = "Company code exists";
            return false;
          }
        }
        for (let j = 0; j < FilterEmplist.length; j++) {
          if (FilterEmplist[j].email == this.register.company_Email) {
            // alert(this.$t("Emailexist"));
            this.errorMessage.companyemailexist = "Company code exists";
            return false;
          }
        }
        for (let e = 0; e < FilterComlist.length; e++) {
          if (FilterComlist[e].company_Email == this.register.company_Email) {
            //alert(this.$t("Emailexist"));
            this.errorMessage.companyemailexist = "Company code exists";
            return false;
          }
        }

        if (this.errorMessage.companyemailexist != "") {
          this.errorMessage.company_Email = "";
        }
        return true;
      } else {
        return false;
      }
    },
    Validatexist() {
      let FilterComlist = this.ComList;
      for (let e = 0; e < FilterComlist.length; e++) {
        if (
          FilterComlist[e].company_Email == this.register.company_Email &&
          FilterComlist[e].companyName.toLowerCase() ==
            this.register.companyName.toLowerCase()
        ) {
          //alert(this.$t("companyexist"));
          //alert(this.$t("Emailexist"));

          this.errorMessage.companyNameexist = "Company code exists";
          this.errorMessage.companyemailexist = "Company code exists";
          // alert(this.$t("Bothexist"));
          return false;
        }
      }
      return true;
    },
    ValidationTele() {
      let phone = this.register.tele_Number;

      for (let j in this.ComList) {
        if (phone == this.ComList[j].tele_Number) {
          //alert(this.$t("Phoneexist"));
          this.errorMessage.teleexist = "Company code exists";
          return false;
        }
      }
      return true;
    },
    ValidateTrue(obj) {
      return Object.values(obj).every((x) => x);
    },
    checkEmail() {
      let email = this.register.company_Email;
      var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email) || false;
    },

    checkTele() {
      let teleNo = this.register.tele_Number;
      if (teleNo.length >= 9) return true;
      return false;
    },
    checkCity() {
      let teleNo = this.register.city;
      // let mytele= teleNo.substring(0,1);
      // alert(mytele);
      while (teleNo.charAt(0) === " ") {
        teleNo = teleNo.substring(1);
      }
      if (teleNo.length >= 3) return true;
      return false;
    },
    checkCountry() {
      let teleNo = this.register.country;
      while (teleNo.charAt(0) === " ") {
        teleNo = teleNo.substring(1);
      }
      if (teleNo.length >= 3) return true;
      return false;
    },
    ValidateCompanyname() {
      let company = this.register.companyName;
      for (let j in this.ComList) {
        if (
          company.toLowerCase() == this.ComList[j].companyName.toLowerCase()
        ) {
          //self.successMessage=self.$t("forgetpassw");
          // alert(this.$t("companyexist"));
          this.errorMessage.companyNameexist = "Company code exists";
          return false;
        }
      }
      return true;
    },
    ValidationCompanyCode() {
      let code = this.register.companyCode;
      for (let j in this.ComList) {
        if (code.toLowerCase() == this.ComList[j].companyCode.toLowerCase()) {
          // alert(this.$t("CompanyCodeexist"));

          this.errorMessage.companycodeexist = "Company code exists";

          return false;
        }
      }
      return true;
    },
    ValidatePwd(){
      if(this.register.password != this.register.cpassword){
        this.errorMessage.cpasswordNM = "Password doesn't match";
        return false;
      }
      return true;
    },
    errorMessages() {
      let { errorMessage, register } = this;
        errorMessage.companysize = !register.companySize
          ? "ต้องการชื่อบริษัท"
          : "";
        errorMessage.companyName = !register.companyName
          ? "ต้องการชื่อบริษัท"
          : "";
        errorMessage.businesstype = !register.businesstype
          ? "ต้องการชื่อบริษัท"
          : "";
        errorMessage.contact_Name = !register.contact_Name
          ? "ต้องการชื่อบริษัท"
          : "";
        errorMessage.password = !register.password
          ? "ต้องการชื่อบริษัท"
          : "";
        errorMessage.cpassword = !register.cpassword
          ? "ต้องการชื่อบริษัท"
          : "";

        if(register.businesstype==30)
        {
           errorMessage.othertype = !register.othertype
        ? "ต้องการชื่อบริษัท"
        : "";
        }
        else{
          register.othertype="ex"
        }

      errorMessage.companyCode = !register.companyCode
        ? "ต้องระบุรหัส บริษัท"
        : "";
      errorMessage.company_Address = !register.company_Address
        ? "ต้องการที่อยู่บริษัท"
        : "";

      // ต้องกรอกชื่อผู้ติดต่อ
      if (this.showeng == true) {
        errorMessage.username = !register.contact_Name
          ? "Contact Name is required"
          : "";
      } else {
        errorMessage.username = !register.contact_Name
          ? "ต้องกรอกชื่อผู้ติดต่อ"
          : "";
      }

      if (this.showeng != true) {
        errorMessage.tele_Number = !register.tele_Number
          ? "กรุณาระบุ"
          : !this.checkTele()
          ? "หมายเลขโทรศัพท์จะต้องเป็น 9 ถึง 11 หลักโปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง"
          : "";
      } else {
        errorMessage.tele_Number = !register.tele_Number
          ? "Please complete this required field"
          : !this.checkTele()
          ? "Telephone number must be 9 to 11 digits.Please enter a valid phone number"
          : "";
      }
      if (this.showeng != true) {
        errorMessage.city = !register.city
          ? "กรุณาระบุ"
          : !this.checkCity()
          ? "ชื่อเมืองต้องมีความยาวอย่างน้อย 3 อักษร"
          : "";
      } else {
        errorMessage.city = !register.city
          ? "Please complete this required field"
          : !this.checkCity()
          ? "City name must be at least 3 characters long"
          : "";
      }
      if (this.showeng != true) {
        errorMessage.country = !register.country
          ? "กรุณาระบุ"
          : !this.checkCountry()
          ? "อประเทศต้องมีความยาวอย่างน้อย 3 อักษร"
          : "";
      } else {
        errorMessage.country = !register.country
          ? "Please complete this required field"
          : !this.checkCountry()
          ? "Country name must be at least 3 characters long"
          : "";
      }
      // errorMessage.companyemailexist="";
      if (this.showeng != true) {
        errorMessage.company_Email = !register.company_Email
          ? "กรุณาระบุ"
          : !this.checkEmail()
          ? "กรุณาใส่อีเมล์ที่ถูกต้อง"
          : "";
      } else {
        errorMessage.company_Email = !register.company_Email
          ? "Please complete this required field"
          : !this.checkEmail()
          ? "Please enter correct email"
          : "";
      }
      if (errorMessage.company_Email != "") {
        errorMessage.companyemailexist = "";
      }

      if (this.checkapp != true) {
        this.showerror = true;
        this.time++;
        return false;
      } else {
        this.showerror = false;
      }
      this.time++;
      return this.checkEmail() &&
        this.checkTele() &&
        this.checkCity() &&
        this.checkCountry()
        ? true
        : false;
    },

    async AddCompany() {
      // eslint-disable-next-line no-useless-catch
      try {
        let self = this;
        //self.register;
        //console.log(self.register);
        let errorTeleEmial = this.errorMessages();
        let tempvalid = this.ValidateTrue(this.register);
        this.register.is_Locked = true;
        let validatecomexist = this.Validatexist();
        //let validateemail = this.ValidateEmail();
        let validatecomname = this.ValidateCompanyname();
        let validateCode = this.ValidationCompanyCode();
        let validateemail = this.ValidateEmail();
        // let validaeTele = this.ValidationTele();
        // let validateusername = this.ValidateUserName();
        let validatepwd = this.ValidatePwd();
        if (
          tempvalid == true &&
          errorTeleEmial == true &&
          validatecomexist == true &&
          validatecomname == true &&
          validateCode == true && 
          validatepwd == true &&
          // validaeTele == true &&
          validateemail == true 
          // validateusername ==true
        ) {
          //  if (validateemail == true) {

          // if (validateusername == true) {
          this.loading = true;
          self.register.package_ID = 1;
          self.register.companySize=parseInt(self.register.companySize);
          
          //  this.register.username = this.register.company_Email;
          this.register.is_Locked = false;
          console.log(this.register)
          const response = await axios.post(
            `${this.url}Company/RegisterCompany`,
            this.register
          );
          
          if (response.data.status == 0) {
            // alert(
            //   "Your account is now ready for use. Please check your inbox for the email with login details. If you did not receive an email, please check your junk mailbox. If the problem still exists, please email us at sales@fusionsol.com"
            // );

            //alert(this.$t("AccountSuccess"));
            //this.LogTrace(null, "Company Registration", 45, "Low");
            this.thankyou = true;
            this.register = {};
            this.packagename = "";
            this.activeemp = null;
            //this.GetUserName();
            //this.GetCompanyList();
            //top.location = "https://optimisticwebdev.optimistic-app.com/";
            //window.open("https://optimisticdevs.azurewebsites.net/", "_blank");
          } else {
            alert(response.data.message);
          }
          this.loading = false;

          // else {
          //   window.location = "https://opmisticstaging.azurewebsites.net/";
          // }
          // }
          // }
        }
      } catch (error) {
        alert(error);
        this.LogTrace(error, "Company Registration Fail", 45, "Low");
      }
    },
    GotoHome(){
      //top.location = "https://optimisticwebdev.optimistic-app.com/"; //dev
      //top.location = "https://blue-smoke-04d666000.3.azurestaticapps.net/"; //stg
      //top.location = "https://optimistic-app-cust-slot.azurewebsites.net/"; //slot
      top.location = "https://optimistic-app-cust.azurewebsites.net/"; //prod
    },
  },
};
</script>

<style scoped>
@media (min-width: 1280px) {
  .headtext {
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  .headtext {
    font-size: 10px;
  }
}
.gg {
  height: auto;
  overflow: hidden;
}
.btn {
  border-radius: 20px;
}

@media (max-width: 580px) {
  .iconi {
  font-size:13px !important;
}
}
@media (min-width:1281px )
{
  .iconi{
    font-size:18px !important;
  }
}
@media(min-width: 560px) and (max-width: 1280px)
{
  .iconi{
    font-size:15px !important;
  }
}
.lefz{
  padding-left: 0px;
  margin-left: 0px;
}
.rghz{
  padding-right: 0px;
  margin-right: 0px;
}
@media (max-width:959px )
{
  .lefz{
    padding-left: 5px;
    margin-left: 5px;
  }
  .rghz{
    padding-right: 5px;
    margin-right: 5px;
  }
}
::v-deep.v-text-field input {
    font-size: 14px;
    font-style: normal;
    font-weight: lighter;
    color: #4F4F4F;
    font-family: 'Kanit' !important;
}
::v-deep.v-textarea textarea {
  font-size: 14px;
  font-style: normal;
  font-weight: lighter;
  color: #4F4F4F;
  font-family: 'Kanit' !important;
}
::v-deep.v-input .v-label {
    font-size: 14px;
    font-weight: lighter;
    color: #4F4F4F;
    font-family: 'Kanit' !important;
}
.v-text-field--outlined ::v-deep fieldset {
  border-color: #dbdbdb;
}
.v-text-field--outlined.v-input--is-focused ::v-deep fieldset {
  border-color: #F99D20;
}
/* * {
    font-family: 'Open Sans', sans-serif !important;
} */
::v-deep.v-list-item__title, .v-list-item__subtitle {
    font-size: 14px !important;
    font-family: 'Kanit' !important;
}
::v-deep.autocomplete-overlay .v-list-item {
  min-height: 10px !important;
}
::v-deep.autocomplete-overlay .v-list-item-title {
  font-size: 14px !important;
  font-family: 'Kanit' !important;
}
</style>
