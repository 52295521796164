<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div v-show="addemployeeadd">
      <div style="margin-left: 4px;margin-top: 22px;display:flex;">
        <v-btn router icon color="white" :to="{ name: 'UserManagement' }">
          <img src="@/assets/images/back button.png" class="mt-1" />
        </v-btn>
        <p class="txt-header">
          {{ $t("newEmployee") }}
        </p>
      </div>
      <!-- <div style="background-color: #f0f0f7">
        <v-row>
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'UserManagement' }"
            class="mt-7 ml-3"
          >
            <img src="@/assets/images/back button.png" class="ml-3 mt-1" />
          </v-btn>
          <p
            class="headertext pb-0"
            style="
              margin-left: 7px;
              margin-top: 22px;
            "
          >
            {{ $t("newEmployee") }}
          </p>
        </v-row>
      </div> -->
      <!-- <v-form v-model="addValue"> -->
      <v-card class="mt-3 card" ref="form" style="margin-bottom: 5rem">
        <div class="d-flex">
          <div class="pt-5 ml-7">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/addemployee.png"
            />
          </div>
          <div class="ml-3 pt-5">
            <label style="font-size: 16px; color: #f99d20">{{
              $t("employeeinformation")
            }}</label>
          </div>
        </div>

        <div class="content" style="margin-right: 2rem; margin-left: 2rem">
          <v-form ref="form1" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("employeeid")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  ref="refID"
                  color="#F99D20"
                  :rules="IDAdd"
                  v-model="employee.ID"
                  class="empID"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("email") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  ref="refemail"
                  color="#F99D20"
                  :rules="emailAdd"
                  v-model="employee.email"
                  class="empID"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("fullName") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  ref="reffullname"
                  :rules="fullnameAdd"
                  v-model="employee.fullname"
                  class="empID"
                  placeholder="eg:Mg Mg Mg"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("position") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  ref="refposition"
                  color="#F99D20"
                  :rules="positionAdd"
                  v-model="employee.position"
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <div class="d-flex">
          <div class="mt-3 ml-7">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/infoForUser.png"
            />
          </div>
          <div class="ml-3 mt-3">
            <label style="font-size: 16px; color: #f99d20">{{
              $t("userInformation")
            }}</label>
          </div>
        </div>
        <div class="content" style="margin-left: 2rem; margin-right: 2rem">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <label style="font-size: 12px"
                >{{ $t("username") }}<span class="red--text"> *</span></label
              >
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="uidAdd"
                v-model="uid"
                class="empID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <label style="font-size: 12px"
                >{{ $t("password") }}<span class="red--text"> *</span></label
              >
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="pwdAdd"
                v-model="pwd"
                class="empID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" md="3" class="pl-5 pt-5">
              <v-btn
                :disabled="lockdisable"
                class="mt-5 lock"
                icon
                @click="showlock = !showlock"
              >
                <img :src="showlock ? lockimage.lock : lockimage.unlock" />
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <v-slide-x-reverse-transition>
          <v-tooltip v-if="formHasErrors" left>
            <template v-slot:activator="{ on }">
              <v-btn icon class="my-0" @click="resetForm" v-on="on">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh form</span>
          </v-tooltip>
        </v-slide-x-reverse-transition>
        <v-card-actions class="mr-5 pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize btn"
            width="120"
            height="40"
            text
            router
            :to="{ name: 'UserManagement' }"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="#F99D20"
            class="mr-4 text-capitalize"
            width="120"
            height="40"
            @click="Addemployee"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
      <!-- </v-form> -->
    </div>

    <div v-show="!addemployeeadd">
      <div style="margin-left: 4px;margin-top: 22px;display:flex;">
        <v-btn router icon color="white" :to="{ name: 'UserManagement' }">
          <img src="@/assets/images/back button.png" class="mt-1" />
        </v-btn>
        <p class="txt-header">
          {{ $t("editEmployee") }}
        </p>
      </div>
      <!-- <div style="background-color: #f0f0f7">
        <v-row>
          <v-btn
            icon
            router
            color="white"
            :to="{ name: 'UserManagement' }"
            class="ml-3 mt-7"
          >
            <img src="@/assets/images/back button.png" class="ml-3 mt-1" />
          </v-btn>
          <p
            class="headertext mb-0"
            style="
              margin-left: 7px;
              margin-top: 22px;
            "
          >
            {{ $t("editEmployee") }}
          </p>
        </v-row>
      </div> -->
      <v-card class="dialogmargin card py-5" style="margin-bottom: 5rem">
        <div class="d-flex">
          <div class="ml-7 mt-5">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/addemployee.png"
            />
          </div>
          <div class="ml-3 pt-5">
            <label style="font-size: 16px; color: #f99d20">{{
              $t("employeeinformation")
            }}</label>
          </div>
        </div>

        <div class="content" style="margin-left: 2rem; margin-right: 2rem">
          <v-form ref="formedit" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("employeeid")
                  }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  :rules="IDEdit"
                  v-model="update.ID"
                  class="empID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("email") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  :rules="emailEdit"
                  v-model="update.email"
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("fullName") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  :rules="fullnameEdit"
                  v-model="update.fullname"
                  class="empID"
                  placeholder="eg:Mg Mg Mg"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <label style="font-size: 12px"
                  >{{ $t("position") }}<span class="red--text"> *</span></label
                >
                <v-text-field
                  autocomplete="off"
                  color="#F99D20"
                  :rules="positionEdit"
                  v-model="update.position"
                  class="empID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <div class="d-flex">
          <div class="mt-3 ml-7">
            <img
              width="24px"
              height="24px"
              style="margin-top:auto;margin:bottom:auto;"
              src="@/assets/images/infoForUser.png"
            />
          </div>
          <div class="ml-3 mt-3">
            <label style="font-size: 16px; color: #f99d20">{{
              $t("userInformation")
            }}</label>
          </div>
        </div>
        <div class="content" style="margin-left: 2rem; margin-right: 2rem">
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <label style="font-size: 12px; margin-bottom: 6px"
                >{{ $t("username") }}<span class="red--text"> *</span></label
              >
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="uidEdit"
                v-model="update.uid"
                style="margin-top: 13px"
                class="empID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="4">
              <label style="font-size: 12px"
                >{{ $t("password") }}<span class="red--text"> *</span></label
              >
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="pwdEdit"
                v-model.lazy="update.pwd"
                class="empID"
                style="margin-top: 13px"
                :type="show1 ? 'text' : 'password'"
                v-on:keyup="keymonitor"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="1" md="3" class="pl-5 pt-8">
              <v-btn
                :disabled="lockdisable"
                class="mt-5"
                icon
                @click="showlock = !showlock"
              >
                <img :src="showlock ? lockimage.lock : lockimage.unlock" />
              </v-btn>
            </v-col>
          </v-row>
        </div>
      
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize btn"
            width="110"
            height="40"
            router
            :to="{ name: 'UserManagement' }">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-4 text-capitalize save"
            width="110"
            height="40"
            @click="UpdateEmployee">
            {{ $t("Update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      
      <v-dialog v-model="loadingdialog" persistent width="300">
        <v-card color="#FFF4EB" dark>
          <v-card-text class="black--text">
            Loading Please Wait...
            <v-progress-linear
              indeterminate
              color="#F99D20"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
import { mask } from "vue-the-mask";
export default {
  mixins: [LogTrace],
  directives: {
    mask,
  },
  watch: {
    "employee.email"() {
      //this.emailrules = [];
      this.emailAdd = [];
    },
    "employee.ID"() {
      this.IDAdd = [];
    },
    "employee.fullname"() {
      this.fullnameAdd = [];
    },
    "employee.position"() {
      this.positionAdd = [];
    },
    uid() {
      this.uidAdd = [];
    },
    pwd() {
      this.pwdAdd = [];
    },
    "update.ID"() {
      this.IDEdit = [];
    },
    "update.email"() {
      //this.emailrulesedit = [];
      this.emailEdit = [];
    },
    "update.fullname"() {
      this.fullnameEdit = [];
    },
    "update.position"() {
      this.positionEdit = [];
    },
    "update.uid"() {
      this.uidEdit = [];
    },
    "update.pwd"() {
      this.pwdEdit = [];
    },
  },
  data() {
    return {
      required(prop) {
        return (v) => !!v || `${prop} is required`;
      },
      //  emailRules: [
      //     v => !!v || 'E-mail is required',
      //     v => /.+@.+/.test(v) || 'E-mail must be valid',
      //   ],
      DigitRules: [
        (v) => !!v || "Employee ID is required",
        (v) => /^\d*\.?\d*$/.test(v) || "Only digit allow",
      ],
      Namerules: [
        (v) => !!v || "Firstname and Middlename and Surname is required",
        // v => /^\d*\.?\d*$/.test(v) || 'Please use space between Firstname and Middlename and Surname',
        (v) => this.validatefullname(v) || "please space between two words",
      ],
      loadingdialog: false,
      emailrules: [],
      emailrulesedit: [],
      first: ".....",
      addValue: false,
      addroute: null,
      addemployeeadd: true,
      uid: null,
      pwd: null,
      characters: "a-z,A-Z,0-9",
      emaillist: [],
      userlist: [],
      companylist: [],
      employeelist: [],
      rules: {
        required: (value) => !!value || "Required.", //if value exist dont show if not show the error
        min: (v) => v.length >= 4 || "Min 4 numbers",
      },
      mask: "####",
      size: 10,
      url: enurl.apiUrl,
      employee: {
        ID: null,
        email: null,
        fullname: null,
        position: null,
      },
      update: {
        ID: 0,
        EmpID:0,
        email: null,
        fullname: null,
        position: null,
        uid: null,
        pwd: null,
      },
      employeeList: [],
      empID: 0,
      showlock: false,
      lockdisable: true,

      lockimage: {
        lock: require("@/assets/images/padlock.png"),
        unlock: require("@/assets/images/padunlock.png"),
      },
      formHasErrors: false,
      refID: null,
      refemail: null,
      reffullname: null,
      refposition: null,
      IDAdd: [],
      emailAdd: [],
      fullnameAdd: [],
      positionAdd: [],
      uidAdd: [],
      pwdAdd: [],
      IDEdit: [],
      emailEdit: [],
      fullnameEdit: [],
      positionEdit: [],
      uidEdit: [],
      pwdEdit: [],
      error: {
        errorMessageID: "",
        errorMessages: "",
        errorMessagefullname: "",
        errorMessageposition: "",
        errorMessageuerid: "",
        errorMessagepsw: "",
      },
      valid: false,
      validateuseremail: true,
      validateuseremp:true,
      pwdimage1: {
        showpwd: require("@/assets/images/reveal icon@2x.png"),
        hidepwd: require("@/assets/images/pass@2x.png"),
      },
      show1: false,
      usernamelist: [],
    };
  },
  computed: {
    form() {
      return {
        ID: this.refID,
        Email: this.refemail,
        fullname: this.reffullname,
        position: this.refposition,
      };
    },
  },

  async mounted() {
    try
    {
      let self = this;
      self.loadingdialog = true;
      await self.ShowHide();
      self.pwd = self.generate();
      self.uid = self.generateUserID();
      await self.GetUser();
      await self.GetPersonalinfo();
      await self.GetCompanyinfo();
      await self.GetUserName();
      self.loadingdialog = false;
    }
    catch(error)
    {
      self.loadingdialog = false;
      alert(error);
    }
  },

  created() {
    this.addroute = this.$route.params.addemployee;
  },

  methods: {
    async GetUserName() {
      let self = this;
      await axios.get(`${self.url}UserInfo/GetUserInfos`).then(function(response)
      {
        self.usernamelist = response.data.data;
      });
    },

    ValidateAccount() {
      let self = this;
      let FilterUserNameList = self.usernamelist;
      for (let i = 0; i < FilterUserNameList.length; i++) 
      {
          if (FilterUserNameList[i].userName.toLowerCase() === self.uid.toLowerCase()) 
          {
              alert("This username is already taken by another user. Please use a different username.");
              return false;
          }
      }
      return true;
    },

    ValidateAccountForUpdate() {
      let self = this;
      let FilterUserNameList = self.usernamelist.filter((person) => person.employeeId != parseInt(self.$route.params.empid));
      if (FilterUserNameList.some(person => person.userName.toLowerCase() === self.update.uid.toLowerCase())) 
      {
          alert("This username is already taken by another user. Please use a different username.");
          return false;
      }
      return true;
    },

    keymonitor: function(event) {
      if (event.key == "Backspace")
      {
        this.update.pwd = null;
        this.show1 = true;
      }
    },

    passwordchange() {
      let self = this;
      if (self.update.pwd != null)
      {
        self.update.pwd = "......";
      }
      else
      {
        self.update.pwd = null;
      }
    },

    async ShowHide() {
      let self = this;
      if (self.addroute == "addemployee")
      {
        self.addemployeeadd = true;
      }
      else
      {
        await self.GetEmpInfoByID();
        self.addemployeeadd = false;
      }
    },

    validateDigit(emppid) {
      let emm = /^\d*\.?\d*$/;
      return emm.test(emppid) || "Only digit allow";
    },

    validatenumber(empeeid) {
      let phoneno = /^\d{4}$/;
      return phoneno.test(empeeid) || "Invalid Phone format";
    },

    validatefullname(str) {
      //eslint-disable-line
      //eslint-disable-next-line
      return /[\-\w]+\s[\-\w]/.test(str) || "please one space between two words";
      //return /^([a-zA-Z0-9]+\s?)+$/.test(str) || "please space";
     // return /\s/.test(str) || "please space between two words";
    },

    validatespaceforfullname(str)
    {
      let mytest=/(\s\s)/.test(str) ;
      let retunrdata=true;
      if(mytest==true)
      {
        retunrdata=false;
      }
      return retunrdata  || "Please space one time betweeen words"
    },

    async GetEmpInfoByID() {
      try {
        let self = this;
        self.empID = parseInt(self.$route.params.empid);
        let tempID = self.empID;
        let temp = {
          employee_ID: tempID,
        };
        await axios.post(`${self.url}EmployeeInfo/GetEmployeeInfoByID`, temp)
          .then((response) => {
            self.employeeList = response.data.data;
            if(self.employeeList!=null)
            {
              self.update.EmpID=self.employeeList[0].employee_ID;
              self.update.ID = self.employeeList[0].employee_Code;
              self.update.email = self.employeeList[0].email;
              self.update.fullname = self.employeeList[0].fullName;
              self.update.position = self.employeeList[0].position;
              self.update.uid = self.employeeList[0].username;
              self.update.pwd = self.employeeList[0].password;
              let lckshow = self.employeeList[0].is_Locked;
              if (lckshow == true)
              {
                self.showlock = lckshow;
                self.lockdisable = false;
              }
              else
              {
                self.showlock = lckshow;
                self.lockdisable = true;
              }
            }
            else
            {
              self.update.ID = null;
              self.update.email = null;
              self.update.fullname = null;
              self.update.position = null;
              self.update.uid = null;
              self.update.pwd = null;
            }
            //console.log(self.update.ID);
          });
      } 
      catch (error) 
      {
        alert(error);
      }
    },

    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";
      if (charactersArray.indexOf("a-z") >= 0)
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0)
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0)
      {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0)
      {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }
      for (let i = 0; i < this.size; i++)
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },

    generateUserID() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";
      if (charactersArray.indexOf("a-z") >= 0)
      {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0)
      {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++) 
      {
        tempPassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      return tempPassword;
    },

    async GetUser() {
      let self = this;
      await axios.get(`${self.url}EmployeeInfo/GetEmployeeInfo`)
        .then(function(response) {
          self.userlist = response.data.data;
          //self.LogTrace(null, "Successfully", 13, "Critical");
        });
    },

    async GetPersonalinfo() {
      let self = this;
      await axios.get(`${self.url}EmployeeInfo/GetPersonalinfo`)
        .then(function(response) {
          self.employeelist = response.data.data;
        });
    },

    async GetCompanyinfo() {
      let self = this;
      await axios.get(`${self.url}Company/GetCompany`).then(function(response) {
        self.companylist = response.data.data;
      });
    },

    validateEmpcodeexist(e)
    {
      let self=this;
      self.validateuseremp=true;
      self.userlist.map((user)=>{
        if(self.validateuseremp==true)
        {
          if(user.employee_Code==e)
          {
            self.validateuseremp=false
          }
          else
          {
            self.validateuseremp=true;
          }
        } 
      })

      if(self.validateuseremp==true)
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    validateEmpcodeexistforupdate(e)
    {
      let self=this;
      self.validateuseremp=true;
      self.userlist.map((user)=>{
        if(self.validateuseremp==true)
        {
          if(user.employee_Code==e)
          {
              if(user.employee_ID==self.update.EmpID)
              {
                self.validateuseremp=true;
              }
              else
              {
                self.validateuseremp=false;
              }
          }
          else
          {
            self.validateuseremp=true;
          }
        }
      })
      if(self.validateuseremp==true)
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    validateEmailexist(e) {
      let self = this;
      self.userlist.map((user) => {
        if (self.validateuseremail == true) 
        {
          if (user.email == e) 
          {
            self.validateuseremail = false;
          } 
          else 
          {
            self.validateuseremail = true;
          }
        }
      });
    },

    validateEmailexistwithemployee(e) {
      let self = this;
      self.employeelist.map((user) => {
        if (self.validateuseremail == true) 
        {
          if (user.email == e) 
          {
            self.validateuseremail = false;
          } 
          else 
          {
            self.validateuseremail = true;
          }
        }
      });
    },

    vaidateemailwithcompany(e) {
      let self = this;
      self.companylist.map((user) => {
        if (self.validateuseremail == true) 
        {
          if (user.company_Email == e) 
          {
            self.validateuseremail = false;
          } 
          else 
          {
            self.validateuseremail = true;
          }
        }
      });
    },

    validateEmailexistforEdit(e) {
      let self = this;
      let FilterUserNameList = self.userlist.filter(
        (user) => user.employee_ID != self.empID
      );
      FilterUserNameList.map((user) => {
        if (self.validateuseremail == true) 
        {
          if (user.email == e) 
          {
            self.validateuseremail = false;
          } 
          else 
          {
            self.validateuseremail = true;
          }
        }
      });
    },

    async Addemployee() {
      try 
      {
        let self = this;
        self.loadingdialog = true;
        self.validateuseremp=true;
        self.emailrules = [
          (v) => !!v || "Email is required",
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
        ];
        self.IDAdd = [
          (v) => !!v || "Employee ID is required",
          (v) => this.validateEmpcodeexist(v) || "Employee Code is Duplicated",
        ];
        self.emailAdd = [
          (v) => !!v || "Email is required",
          (v) => this.validateEmail(v) || "E-mail must be valid",
          //(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
        self.fullnameAdd = [
          (v) => !!v || "Full Name is required",
          (v) => this.validatefullname(v) || "please space between two words", 
          (v)=> this.validatespaceforfullname(v) || "Please space one time between words"
        ];
        self.positionAdd = [(v) => !!v || "Position is required"];
        self.uidAdd = [(v) => !!v || "Username is required"];
        self.pwdAdd = [(v) => !!v || "Password is required"];
        // await this.validateEmailexist(this.employee.email);
        // await this.validateEmailexistwithemployee(this.employee.email);
        // await this.vaidateemailwithcompany(this.employee.email);

        var tempvalidate = self.$refs.form1.validate();
        let IsUsernameExisted = self.ValidateAccount();
        let validateempcode=self.validateEmpcodeexist(self.employee.ID);
        let validatefullname=self.validatefullname(self.employee.fullname);
        let validatespace=self.validatespaceforfullname(self.employee.fullname)
         let validateemailforupdate=self.validateEmail(self.employee.email);
       
        if (IsUsernameExisted) 
        {
          if (
            validateemailforupdate==true &&
            validateempcode==true &&
            validatefullname==true &&
            validatespace==true &&
            tempvalidate == true &&
            self.employee.ID != null &&
            self.employee.ID != "" &&
            self.employee.email != null &&
            self.employee.email != "" &&
            self.employee.fullname != null &&
            self.employee.fullname != "" &&
            self.employee.position != null &&
            self.employee.position != "" &&
            self.uid != null &&
            self.uid != "" &&
            self.pwd != null &&
            self.pwd != ""
          ) 
          {
            if (this.validateuseremail) 
            {
              this.errorMessages = "";
              let tempUser = {
                employee_Code: self.employee.ID,
                email: self.employee.email,
                fullName: self.employee.fullname,
                position: self.employee.position,
                username: self.uid,
                password: self.pwd,
                is_Locked: self.showlock,
              };

              axios.post(`${self.url}EmployeeInfo/AddEmployeeInfo`, tempUser)
                .then(function(response) {
                  if (response.data.status == 0) 
                  {
                    alert(response.data.message);
                    self.loadingdialog = false;
                    //self.LogTrace(null, "Add Employee", 13, "Low");
                    self.$router.push("/usermanagement").catch(()=>{});
                  }
                })
                .catch(function(error) {
                  alert(error);
                  self.LogTrace(error, "Add Employee Fail", 13, "Low");
                  self.loadingdialog = false;
                });
            } 
            else 
            {
              // this.validateerroradd();
              this.loadingdialog = false;
              alert("This Email has already been used by another user.Please use other Email.");
              self.validateuseremail = true;
            }
          } 
          else 
          {
            self.loadingdialog = false;
            if (
              self.employee.ID == null ||
              self.employee.ID == "" ||
              self.employee.email == null ||
              self.employee.email == "" ||
              self.employee.fullname == null ||
              self.employee.fullname == "" ||
              self.employee.position == null ||
              self.employee.position == "" ||
              self.uid == null ||
              self.uid == "" ||
              self.pwd == null ||
              self.pwd == ""
            )
            {
              alert("Please fill all the fields");
              self.validateuseremail=true;
            }
            else if(!validateempcode)
            {
              alert("Employee Code is duplicated.Please fill different Employee Code.")
            }
            else 
            {
              alert("Please fill correct format");
            }
          }
        } 
        else 
        {
          self.loadingdialog = false;
        }
      } 
      catch (error) 
      {
        self.LogTrace(error, "Add Employee Fail", 13, "Low");
        self.loadingdialog = false;
      }
    },

    validateAddtrue() {
      let self = this;
      let emailvali = self.validateEmail(self.employee.email);
      let IDvali = self.validateDigit(self.employee.ID);
      let namevali = self.validatefullname(self.employee.fullname);
      if (
        self.employee.ID != null &&
        self.employee.email != null &&
        self.employee.fullname != null &&
        self.employee.position != null &&
        self.employee.position != "" &&
        self.uid != null &&
        self.uid != "" &&
        self.pwd != null &&
        self.pwd != "" &&
        namevali == true &&
        emailvali == true &&
        IDvali == true
      ) 
      {
        return true;
      } 
      else 
      {
        return false;
      }
    },

    validateerroradd() {
      let self = this;
      let emailvali = self.validateEmail(self.employee.email);
      let IDvali = self.validateDigit(self.employee.ID);
      let namevali = self.validatefullname(self.employee.fullname);
      if (
        self.employee.ID == null ||
        self.employee.email == null ||
        self.employee.fullname == null ||
        self.employee.position == null ||
        self.employee.position == "" ||
        self.uid == "" ||
        self.pwd == "" ||
        self.uid == null ||
        self.pwd == null
      ) 
      {
        alert("Please fill in the required fields");
      }
      else if (emailvali != true && IDvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.employee.email != emailvali ? "Incorrect email format" : this.employee.email;
        this.error.errorMessageID = this.employee.ID != IDvali ? "Please fill only number" : this.employee.ID;
        this.error.errorMessagefullname = this.employee.fullname != namevali ? "Please use space between firstname and Middlename and Surname" : this.employee.fullname;
      }
      else if (emailvali != true && IDvali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.employee.email != emailvali ? "Incorrect email format" : this.employee.email;
        this.error.errorMessageID = this.employee.ID != IDvali ? "Please fill only number" : this.employee.ID;
      }
      else if (emailvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.employee.email != emailvali ? "Incorrect email format" : this.employee.email;
        this.error.errorMessagefullname = this.employee.fullname != namevali ? "Please use space between firstname and Middlename and Surname" : this.employee.fullname;
      }
      else if (IDvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessageID = this.employee.ID != IDvali ? "Please fill only number" : this.employee.ID;
        this.error.errorMessagefullname = this.employee.fullname != namevali ? "Please use space between firstname and Middlename and Surname" : this.employee.fullname;
      }
      else if (emailvali != true)
      {
        alert("please fill the correct format");
        this.error.errorMessages = this.employee.email != emailvali ? "Incorrect email format" : this.employee.email;
      }
      else if (IDvali != true)
      {
        alert("please fill the ID correct format");
        this.error.errorMessageID = this.employee.ID != IDvali ? "Please fill only number" : this.employee.ID;
      }
      else if (namevali != true)
      {
        alert("Please fill Firstname and Middlename and Surname");
        this.error.errorMessagefullname = this.employee.fullname != namevali ? "Please use space between firstname and Middlename and Surname" : this.employee.fullname;
      }
    },

     validateEmail(email) {
      var re =
        /* eslint-disable-next-line */
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || false;
    },

    async UpdateEmployee() {
      try 
      {
        let self = this;
        self.loadingdialog = true;
        // self.emailrulesedit = [
        //   (v) => !!v || "Email is required",
        //   (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
         
        // ];
        self.IDEdit = [
          (v) => !!v || "Employee ID is required",
           (v) => this.validateEmpcodeexistforupdate(v) || "Employee Code is Duplicated",
        ];
        self.emailEdit = [
          (v) => !!v || "Email is required",
          (v) => this.validateEmail(v) || "E-mail must be valid",
         // (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
        self.fullnameEdit = [
          (v) => !!v || "Full Name is required",
          (v) => this.validatefullname(v) || "please space between two words",
           (v) => this.validatespaceforfullname(v) || "please space one time between words",
        ];
        self.positionEdit = [(v) => !!v || "Position is required"];
        self.uidEdit = [(v) => !!v || "Username is required"];
        self.pwdEdit = [(v) => !!v || "Password is required"];
        // await this.validateEmailexistforEdit(this.update.email);
        // await this.validateEmailexistwithemployee(this.update.email);
        // await this.vaidateemailwithcompany(this.update.email);
        var editvalidate = self.$refs.formedit.validate();
        let IsUsernameExisted = self.ValidateAccountForUpdate();
        let validateempcode=self.validateEmpcodeexistforupdate(self.update.ID);
        let validatefullname=self.validatefullname(self.update.fullname);
        let validatespace=self.validatespaceforfullname(self.update.fullname);
        let validateemailforupdate=self.validateEmail(self.update.email);
        // var editvalidate = this.validateedittrue();
       
        if (IsUsernameExisted == true)
        {
          if (
            validatefullname==true &&
            validatespace==true &&
            validateemailforupdate==true &&
            validateempcode==true &&
            editvalidate == true &&
            self.update.ID != null &&
            self.update.ID != "" &&
            self.update.email != null &&
            self.update.email != "" &&
            self.update.fullname != null &&
            self.update.fullname != "" &&
            self.update.position != null &&
            self.update.position != "" &&
            self.update.uid != null &&
            self.update.uid != "" &&
            self.update.pwd != null &&
            self.update.pwd != ""
          ) 
          {
            if (this.validateuseremail == true) 
            {
              this.errorMessages = "";
              let temp = {
                employee_ID: self.empID,
                employee_Code: self.update.ID,
                position: self.update.position,
                fullName: self.update.fullname,
                email: self.update.email,
                username: self.update.uid,
                password: self.update.pwd,
                is_Locked: self.showlock,
              };
              axios.post(`${self.url}EmployeeInfo/UpdateEmployeeInfo`, temp)
                .then(function(response) {
                  if (response.data.status == 0)
                  {
                    alert(response.data.message);
                    //self.LogTrace(null, "Update Employee", 13, "Medium");
                    self.loadingdialog = false;
                    self.$router.push("/usermanagement").catch(()=>{});
                  }
                })
                .catch(function(error) 
                {
                  self.LogTrace(error, "Update Employee Fail", 13, "Medium");
                  self.loadingdialog = false;
                });
            }
            else
            {
              self.loadingdialog = false;
              alert("This Email had already been used by another user.Please use other Email");
              self.validateuseremail = true;
            }
          } 
          else 
          {
            self.loadingdialog = false;
            if (
              self.update.ID == null ||
              self.update.ID == "" ||
              self.update.email == null ||
              self.update.email == "" ||
              self.update.fullname == null ||
              self.update.fullname == "" ||
              self.update.position == null ||
              self.update.position == "" ||
              self.update.uid == null ||
              self.update.uid == "" ||
              self.update.pwd == null ||
              self.update.pwd == ""
            ) 
            {
              alert("Please fill all the fields");
              self.validateuseremail=true;
            }
            else if(validateempcode==false)
            {
              alert("Employee Code is duplicated.Please fill different Employee Code.")
            }
            else
            {
              alert("Please fill correct format");
            }
          }
        }
        else
        {
          self.loadingdialog = false;
        }
      }
      catch (error)
      {
        self.LogTrace(error, "Update Employee Fail", 13, "Medium");
        self.loadingdialog = false;
      }
    },

    validateedittrue() {
      let self = this;
      let emailvali = self.validateEmail(self.update.email);
      let IDvali = self.validateDigit(self.update.ID);
      let namevali = self.validatefullname(self.update.fullname);
      if (
        self.update.ID != "" &&
        self.update.ID != null &&
        self.update.email != null &&
        self.update.fullname != null &&
        self.update.position != null &&
        self.update.position != "" &&
        self.update.uid != null &&
        self.update.uid != "" &&
        self.update.pwd != null &&
        self.update.pwd != "" &&
        namevali == true &&
        emailvali == true &&
        IDvali == true
      )
      {
        return true;
      }
      else
      {
        return false;
      }
    },

    validateediterror() {
      let self = this;
      let emailvali = self.validateEmail(self.update.email);
      let IDvali = self.validateDigit(self.update.ID);
      let namevali = self.validatefullname(self.update.fullname);
      if (
        self.update.ID == "" ||
        self.update.ID == null ||
        self.update.email == "" ||
        self.update.email == null ||
        self.update.fullname == "" ||
        self.update.fullname == null ||
        self.update.position == null ||
        self.update.position == "" ||
        self.update.uid == "" ||
        self.update.pwd == "" ||
        self.update.uid == null ||
        self.update.pwd == null
      )
      {
        alert("Please fill in the required fields");
      }
      else if (emailvali != true && IDvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.update.email != emailvali ? "Incorrect email format" : this.update.email;
        this.error.errorMessageID = this.update.ID != IDvali ? "Please fill only number" : this.update.ID;
        this.error.errorMessagefullname = this.update.fullname != namevali ? "Please use space between firstname and Middlename and Surname" : this.update.fullname;
      }
      else if (emailvali != true && IDvali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.update.email != emailvali ? "Incorrect email format" : this.update.email;
        this.error.errorMessageID = this.update.ID != IDvali ? "Please fill only number" : this.update.ID;
      }
      else if (emailvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessages = this.update.email != emailvali ? "Incorrect email format" : this.update.email;
        this.error.errorMessagefullname = this.update.fullname != namevali ? "Please use space between Firstname and Middlename and Surname" : this.update.fullname;
      }
      else if (IDvali != true && namevali != true)
      {
        alert("Please fill the correct format");
        this.error.errorMessageID = this.update.ID != IDvali ? "Please fill only number" : this.update.ID;
        this.error.errorMessagefullname = this.update.fullname != namevali ? "Please use space between Firstname and Middlename and Surname" : this.update.fullname;
      }
      else if (emailvali != true)
      {
        alert("please fill the correct format");
        this.error.errorMessages = this.update.email != emailvali ? "Incorrect email format" : this.update.email;
      }
      else if (IDvali != true)
      {
        alert("please fill the ID correct format");
        this.error.errorMessageID = this.update.ID != IDvali ? "Please fill only number" : this.update.ID;
      }
      else if (namevali != true)
      {
        alert("Please fill Firstname and Middlename and Surname");
        this.error.errorMessagefullname = this.update.fullname != namevali ? "Please use space between Firstname and Middlename and Surname" : this.update.fullname;
      }
    },
  },
};
</script>
<style scoped>
@media (min-width: 280px) and (max-width: 541px) {
  .lock {
    margin-top: -3rem !important;
    margin-left:-0.9rem !important;
  }
  .btn{
     margin-left:-3rem !important;
     width:90px !important;
  }
  /* .save{
     margin-left:-2.5rem !important;
     width:90px !important;
  } */
}
.emp {
  margin-left: 150px;
}

.empID {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}
</style>
